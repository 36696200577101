import axios from 'axios'
import { login, logout, me, updateProfile } from '../api'
import { bugsnagClient } from '../main.js'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    currentUser: null,
  },
  getters: {
    loading(state) {
      return state.loading
    },
    loggedIn(state) {
      return state.currentUser !== null
    },
    currentUser(state) {
      return state.currentUser
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },

    setUser(state, user) {
      state.currentUser = user
    },

    login(state, user) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.api_token
      bugsnagClient.user = {
        id: user.id,
        name: user.name,
        email: user.email,
      }
      state.currentUser = user
      localStorage.setItem('se-token', JSON.stringify(user.api_token))
    },

    logout(state) {
      state.currentUser = null
      axios.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('se-token')
    },
  },
  actions: {
    loading({ commit }) {
      commit('setLoading', true)
    },

    loaded({ commit }) {
      commit('setLoading', false)
    },

    logout({ commit }) {
      return logout().then(() => {
        commit('logout')
      })
    },

    login({ commit }, { email, password }) {
      return login(email, password).then(({ data }) => {
        const user = data.data
        commit('login', user)
        return user
      })
    },

    loginWithToken({ commit }, token) {
      return me(token).then(({ data }) => {
        const user = data.data
        commit('login', user)
        return user
      })
    },

    updateProfile({ commit }, profile) {
      return updateProfile(profile).then(({ data }) => {
        const user = data.data
        commit('setUser', user)
        return user
      })
    },
  }
})
