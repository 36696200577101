<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    confirm() {
      this.$emit('confirm')
    },
    
    close() {
      this.$emit('close')
    },

  }
}
</script>

<template>
<div v-if="isOpen" class="modal active">
  <a @click.prevent="close" href="javascript:;" class="modal-overlay" aria-label="Close"></a>
  <div class="modal-container">
    <div class="modal-header">
      <button @click="close" class="btn btn-clear float-right" aria-label="Close"></button>
      <div class="modal-title h5"><slot name="title"></slot></div>
    </div>
    <div class="modal-body">
      <div class="content">
        <slot></slot>
      </div>
    </div>
    <div class="modal-footer">
      <slot name="actions" :close="close" :confirm="confirm"></slot>
    </div>
  </div>
</div>
</template>