<template>
  <div class="container">
    <SettingsNav />

    <div class="columns col-gapless mb-2">
      <div class="column col-6">
        <h2 class="text-xl lg:text-2xl">Zonas</h2>
      </div>
      <div class="column col-6 text-right">
        <router-link class="btn btn-link" to="/settings/zones"
          ><i class="icon icon-arrow-left"></i> Voltar</router-link
        >
      </div>
    </div>

    <ul class="breadcrumb text-xs mb-2">
      <li class="breadcrumb-item">
        <router-link to="/settings/zones">Zonas</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/settings/zones/create`">Criar</router-link>
      </li>
    </ul>

    <div class="card">
      <div class="card-body">
        <form @submit.prevent="saveZone" class="form-horizontal px-2">
          <div class="form-group" :class="{ 'has-error': errors.name }">
            <div class="col-3">
              <label class="form-label">Nome</label>
            </div>
            <div class="col-9">
              <input class="form-input" type="text" v-model="zone.name" />
              <p v-if="errors.name" class="form-input-hint mb-1">
                {{ errors.name[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="col-3"></div>
            <div class="col-9">
              <button type="submit" class="btn btn-primary">Criar</button>
            </div>
          </div>

          <portal to="toasts">
            <div v-if="error" class="toast toast-error mb-2">
              <button
                type="button"
                @click="error = false"
                class="btn btn-clear float-right"
              ></button>
              Ocorreu um <strong>erro</strong> a tentar criar a zona.
            </div>
          </portal>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createZone } from "../../api/";
import SettingsNav from "../../components/SettingsNav";

export default {
  components: {
    SettingsNav,
  },

  data() {
    return {
      zone: {
        name: "",
      },
      error: false,
      errors: {},
    };
  },

  created() {},

  methods: {
    saveZone() {
      this.error = false;
      this.errors = {};

      createZone(this.zone)
        .then(() => {
          this.$router.push(`/settings/zones?created=true`);
        })
        .catch(({ response }) => {
          this.error = true;
          this.errors = response.data.errors;
        });
    },
  },
};
</script>
