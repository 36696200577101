<template>
  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="container mb-2">
    <div class="columns col-gapless">
      <div class="column col-6">
        <h2 class="text-xl lg:text-2xl">Locais</h2>
      </div>
      <div class="column col-6 text-right">
        <router-link class="btn btn-link" :to="`/clients/${clientId}`"
          ><i class="icon icon-arrow-left"></i> Voltar</router-link
        >
      </div>
    </div>

    <ul class="breadcrumb text-xs mb-2">
      <li class="breadcrumb-item">
        <router-link to="/clients">Clientes</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/clients/${clientId}`">{{
          client.name
        }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/clients/${clientId}/places/create`"
          >Criar</router-link
        >
      </li>
    </ul>

    <div class="card">
      <div class="card-body">
        <form @submit.prevent="savePlace" class="form-horizontal px-2">
          <div class="form-group" :class="{ 'has-error': errors.number }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Número</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="place.number" />
              <p v-if="errors.number" class="form-input-hint mb-1">
                {{ errors.number[0] }}
              </p>
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.name }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Nome</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="place.name" />
              <p v-if="errors.name" class="form-input-hint mb-1">
                {{ errors.name[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">NIF</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="number" v-model="place.vat" />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Contacto</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="tel" v-model="place.phone" />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Email</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="email"
                pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                v-model="place.email"
              />
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.address }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Endereço</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="place.address" />
              <p v-if="errors.address" class="form-input-hint mb-1">
                {{ errors.address[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Código postal</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="place.zip" />
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.city }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Localidade</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="place.city" />
              <p v-if="errors.city" class="form-input-hint mb-1">
                {{ errors.city[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12"></div>
            <div class="w-full lg:w-9/12">
              <EditableMap
                :coordinates="place.coordinates"
                style="height: 225px"
                @change="updateLatLng"
              ></EditableMap>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Latitude</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="place.coordinates.lat"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Longitude</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="place.coordinates.lng"
              />
            </div>
          </div>

          <div
            class="form-group"
            :class="{ 'has-error': errors.zone_id || errors.zone }"
          >
            <div class="w-full lg:w-3/12">
              <label class="form-label">Zona</label>
            </div>
            <div class="w-full lg:w-9/12">
              <select class="form-select" type="text" v-model="place.zone_id">
                <option :value="null">Escolher zona</option>
                <option value="NEW">Criar nova zona</option>
                <option
                  v-for="zone in orderedZones"
                  :value="zone.id"
                  :key="zone.id"
                  >{{ zone.name }}</option
                >
              </select>
              <p v-if="errors.zone_id" class="form-input-hint mb-1">
                {{ errors.zone_id[0] }}
              </p>
              <input
                v-if="place.zone_id == 'NEW'"
                class="mt-1 form-input"
                type="text"
                v-model="place.zone_name"
              />
              <p v-if="errors.zone" class="form-input-hint mb-1">
                {{ errors.zone[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12"></div>
            <div class="w-full lg:w-9/12">
              <button type="submit" class="btn btn-primary">Criar</button>
            </div>
          </div>

          <portal to="toasts">
            <div v-if="error" class="toast toast-error mb-2">
              <button
                type="button"
                @click="error = false"
                class="btn btn-clear float-right"
              ></button>
              Ocorreu um <strong>erro</strong> a tentar criar o local.
            </div>
          </portal>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import EditableMap from "../../components/EditableMap";
import { getClient, createPlace, getZones } from "../../api/";

export default {
  components: {
    EditableMap,
  },

  data() {
    return {
      zones: null,
      client: null,
      place: {
        number: "",
        name: "",
        vat: "",
        email: "",
        phone: "",
        address: "",
        zip: "",
        city: "",
        coordinates: {
          lat: "",
          lng: "",
        },
        zone_id: null,
        zone_name: "",
        open_days_of_the_week: null,
        open_hours_intervals: null,
      },
      error: false,
      errors: {},
    };
  },

  computed: {
    clientId() {
      return this.$route.params.client;
    },

    loading() {
      return !this.client || !this.zones;
    },

    orderedZones() {
      if (!this.zones) return [];
      return this.zones.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  created() {
    this.fetchZones();
    this.fetchClient();
  },

  methods: {
    fetchZones() {
      getZones().then(({ data }) => {
        this.zones = data.data;
      });
    },

    updateLatLng({ coordinates }) {
      this.place.coordinates = coordinates;
    },

    savePlace() {
      this.error = false;
      this.errors = {};

      createPlace(this.clientId, this.place)
        .then(({ data }) => {
          const place = data.data;
          this.$router.push(`/clients/${this.clientId}/places/${place.id}`);
        })
        .catch(({ response }) => {
          this.error = true;
          this.errors = response.data.errors;
        });
    },

    fetchClient() {
      getClient(this.clientId)
        .then(({ data }) => {
          this.client = data.data;
          this.place.number = this.$utils.ltrim(this.client.number, "0");
          this.place.vat = this.client.vat;
          this.place.name = this.client.name;
          this.place.phone = this.client.phone;
          this.place.email = this.client.email;
          this.place.address = this.client.address;
        })
        .catch(() => {
          this.$router.push("/404");
        });
    },
  },
};
</script>
