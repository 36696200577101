<template>
<div class="container">
  <div class="columns col-gapless">
    <div class="column col-6">
      <h2 class="text-xl lg:text-2xl mb-2">Clientes</h2>

      <ul class="breadcrumb text-xs">
        <li class="breadcrumb-item">
          <router-link to="/clients">Clientes</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/clients/create">Criar</router-link>
        </li>
      </ul>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-link mb-2 pb-2" to="/clients"><i class="icon icon-arrow-left"></i> Voltar</router-link>
    </div>
  </div>

    <div class="card">
      <div class="card-body">
        
      <form @submit.prevent="saveClient" class="form-horizontal px-2">
        <div class="form-group" :class="{'has-error': errors.number}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Número</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="client.number">
            <p v-if="errors.number" class="form-input-hint mb-1">{{ errors.number[0] }}</p>
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.name}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Nome</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="client.name">
            <p v-if="errors.name" class="form-input-hint mb-1">{{ errors.name[0] }}</p>
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.vat}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">NIF</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="number" v-model="client.vat">
            <p v-if="errors.vat" class="form-input-hint mb-1">{{ errors.vat[0] }}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Endereço</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="client.address">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Contacto</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="tel" v-model="client.phone">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Email</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="email" pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" v-model="client.email">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Observações</label>
          </div>
          <div class="w-full lg:w-9/12">
            <textarea class="form-input" v-model="client.notes"></textarea>
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12"></div>
          <div class="w-full lg:w-9/12">
            <button type="submit" class="btn btn-primary">Criar</button>
          </div>
        </div>

        <portal to="toasts">
          <div v-if="error" class="toast toast-error mb-2">
            <button type="button" @click="error = false" class="btn btn-clear float-right"></button>
            Ocorreu um <strong>erro</strong> a tentar criar o cliente.
          </div>
        </portal>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { createClient } from '../../api/'

export default {

  data() {
    return {
      client: {
        number: "",
        name: "",
        vat: "",
        emai: "",
        phone: "",
        address: "",
        notes: "",
      },
      error: false,
      errors: {},
    }
  },

  methods: {
    saveClient() {
      this.error = false
      this.errors = {}

      createClient(this.client).then(({ data }) => {
        const client = data.data
        this.$router.push(`/clients/${client.id}`)
      }).catch(({ response }) => {
        this.error = true
        this.errors = response.data.errors
      })
    },
  },

}
</script>
