<template>
<div class="container">
  
  <div class="columns col-gapless mb-3">
    <div class="column col-6">
      <h2 class="text-xl lg:text-2xl">Clientes</h2>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-primary" to="/clients/create"><i class="icon icon-people"></i> Novo Cliente</router-link>
    </div>
  </div>

  <div class="w-full lg:w-6/12 mb-2">
    <div class="flex items-center">
      <label>Procurar:</label>
      <input type="text" class="form-input ml-3" @input="onSearch" v-model="search">
    </div>
  </div>

  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="card card-condensed card-table">
    <div class="card-body">
      <table class="table table-striped table-hover text-xs lg:text-sm">
        <thead>
          <tr>
            <th>No.</th>
            <th class="hidden lg:table-cell">NIF</th>
            <th>Cliente</th>
            <th class="hidden lg:table-cell">Locais</th>
            <th class="hidden lg:table-cell">Equipamentos</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="c-hand" v-for="client in clients" @click="goToClient(client)" :key="client.id">
            <td>{{ client.number }}</td>
            <td class="hidden lg:table-cell">{{ client.vat }}</td>
            <td class="hidden lg:table-cell">{{ client.name }}</td>
            <td class="lg:hidden"><span class="text-gray-500">({{ client.vat }})</span> {{ client.name }}</td>
            <td class="hidden lg:table-cell">{{ client.places_count }}</td>
            <td class="hidden lg:table-cell">{{ client.equipments_count }}</td>
            <td class="lg:hidden">
              <span class="text-xs font-semibold">Locais:</span> {{ client.places_count }} <br />
              <span class="text-xs font-semibold">Equipamentos:</span> {{ client.equipments_count }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="lastPage !== 1" class="card-footer">
      <Pagination :page="currentPage" :last-page="lastPage" @change="changePage" />
    </div>
  </div>

  <portal to="toasts">
    <div v-if="deleted" class="toast toast-success mb-2">
      <button type="button" @click="deleted = false" class="btn btn-clear float-right"></button>
      O cliente foi removido com <strong>sucesso</strong>!
    </div>
  </portal>
</div>
</template>

<script>
import _ from 'lodash'
import { getClients } from '../../api/'
import Pagination from '../../components/Pagination'

export default {

  components: {
    Pagination
  },

  data() {
    return {
      loading: true,
      clients: [],
      currentPage: 1,
      lastPage: null,
      search: '',
      deleted: true,
    }
  },

  created() {
    this.getUrlDeleted()

    this.currentPage = this.getUrlPage()
    this.onUrlChange()

    window.addEventListener('popstate', this.onUrlChange)
    this.$on('beforeDestroy', () => {
      window.removeEventListener('popstate', this.onUrlChange)
    })
  },

  methods: {

    getUrlDeleted() {
      this.deleted = this.$utils.getParamUrl('deleted');
      this.$utils.setParamUrl('deleted', null)
      setInterval(() => {
        this.deleted = false
      }, 1500)
    },

    onUrlChange() {
      let search = this.$utils.getParamUrl('search')
      if (search) {
        this.search = search
      }
      this.fetchClients()
    },

    getUrlPage() {
      let page = parseInt(this.$utils.getParamUrl('page'));
      if (! isNaN(page)) {
        return page;
      }
      return 1;
    },

    changePage(page) {
      this.$utils.setParamUrl('page', page)
      this.currentPage = page
      this.fetchClients()
    },

    onSearch: _.throttle(function() {
      this.$utils.setParamUrl('search', this.search)
      this.changePage(1)
    }, 500),

    fetchClients() {
      this.loading = true

      getClients({ page: this.currentPage, search: this.search }).then(({ data }) => {
        this.clients = data.data
        this.currentPage = data.current_page
        this.lastPage = data.last_page

        this.loading = false
      })
    },

    goToClient(client) {
      this.$router.push(`/clients/${client.id}`)
    },

  },

}
</script>
