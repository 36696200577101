<template>
  <div class="container">
    <div class="columns col-gapless mb-2">
      <div class="column col-6">
        <h2 class="text-xl lg:text-2xl">Mapa</h2>
      </div>
      <div class="column col-6 text-right">
        <button
          @click="search"
          class="btn btn-sm text-center"
          :class="{ disabled: searchDisabled }"
          style="min-width: 95px;"
        >
          <template v-if="!searching">
            <i class="icon icon-refresh mr-1"></i> Procurar
          </template>
          <div v-else class="loading"></div>
        </button>
      </div>
    </div>

    <div v-if="loading" class="hero">
      <div class="loading loading-lg"></div>
    </div>
    <div v-else>
      <NearbyMap
        id="nearbymap"
        :center="center"
        :zoom="zoom"
        :places="filteredPlaces"
        :user-location="userLocation"
        @distanceChanged="distanceChanged"
        @zoomChanged="zoomChanged"
        @centerChanged="centerChanged"
        @setCenter="setCenter"
      >
        <template v-slot:infoWindow="{ place }">
          <UrgentPlaceCard :place="place" @change="updatePlace" />
        </template>
      </NearbyMap>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
import NearbyMap from "../components/NearbyMap";
import UrgentPlaceCard from "../components/UrgentPlaceCard";
import { getNearbyUrgentPlaces } from "../api";

export default {
  components: {
    NearbyMap,
    UrgentPlaceCard,
  },

  data() {
    return {
      userLocation: null,
      places: null,
      searching: false,
      center: null,
      zoom: 17,
      distance: 1,
      lastFetch: null,
    };
  },

  computed: {
    loading() {
      return !this.userLocation || !this.places;
    },

    searchDisabled() {
      return (
        this.searching ||
        !this.center || 
        (this.lastFetch && 
          parseInt(this.lastFetch.distance) === parseInt(this.distance) && 
          this.lastFetch.coordinates.lat === this.center.lat &&
          this.lastFetch.coordinates.lng === this.center.lng)
      );
    },

    filteredPlaces() {
      return this.places.filter((place) => !place.done);
    },
  },

  created() {
    const zoom = parseInt(this.$utils.getParamUrl("zoom"));
    if (zoom) this.zoom = zoom;
    const distance = parseInt(this.$utils.getParamUrl("distance"));
    if (distance) this.distance = distance;
    const center = JSON.parse(this.$utils.getParamUrl("center"));
    if (center) {
      this.fetchNearbyUrgentPlaces(center, this.distance);
    }
  },

  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.fetchNearbyUrgentPlaces(this.userLocation, this.distance);
      });
    }
  },

  methods: {
    fetchNearbyUrgentPlaces(coordinates, distance) {
      this.center = coordinates;
      this.distance = distance;
      this.searching = true;
      this.lastFetch = { coordinates, distance };
      getNearbyUrgentPlaces({
        lat: coordinates.lat,
        lng: coordinates.lng,
        distance,
      })
        .then(({ data }) => {
          this.places = data.data;
          this.searching = false;
        })
        .catch(() => {
          this.places = [];
          this.searching = false;
        });
    },

    centerChanged: _.debounce(function(coordinates) {
      this.center = coordinates;
      this.$utils.setParamUrl("center", JSON.stringify(coordinates));
    }, 500),

    distanceChanged(distance) {
      this.distance = distance;
      this.$utils.setParamUrl("distance", distance);
    },

    zoomChanged(zoom) {
      this.zoom = zoom;
      this.$utils.setParamUrl("zoom", zoom);
    },

    setCenter(coordinates) {
      this.center = coordinates;
      this.search();
    },

    search() {
      if (!this.center) return null;
      
      this.fetchNearbyUrgentPlaces(this.center, this.distance);
    },

    updatePlace(updatedPlace) {
      this.places.map((place) => {
        if (place.id === updatedPlace.id) {
          return Object.assign(place, {
            notes: updatedPlace.notes,
            done: updatedPlace.done,
            to_do: updatedPlace.to_do,
            updated_at: updatedPlace.updated_at,
          });
        }
        return place;
      });
    },
  },
};
</script>
