import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import PortalVue from 'portal-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import * as utils from './shared/utils'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

import './index.css'

export const bugsnagClient = bugsnag({ apiKey: process.env.VUE_APP_BUGSNAG_API_KEY, appVersion: process.env.VUE_APP_VERSION })

moment.locale('pt')

bugsnagClient.use(bugsnagVue, Vue)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  }
})

Vue.use(PortalVue)

Vue.use({
	install (Vue) {
    Vue.prototype.$utils = utils
	}
})

Vue.mixin({
  filters: {
    diffForHumans(value) {
      if (!value || value == '-') return value
      return moment(value).fromNow()
    },
    dateForHumans(value) {
      if (!value || value == '-') return value
      return moment(value).format('MMMM D, YYYY')
    },
    date(value) {
      if (!value || value == '-') return value
      return moment(value).format('YYYY-MM-DD')
    },
    datetime(value) {
      if (!value || value == '-') return value
      return moment(value).format('YYYY-MM-DD HH:mm')
    },
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
