<template>
<div v-if="! maintenance" class="hero">
    <div class="loading loading-lg"></div>
</div>
<div v-else class="container">
  <div class="columns col-gapless">
    <div class="column col-6">
      <h2 class="text-2xl mb-2">Manutenções</h2>

      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/maintenances">Manutenções</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="`/maintenances/${maintenance.id}`">{{ maintenance.number }}</router-link>
        </li>
      </ul>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-link mb-2 pb-2" to="/maintenances"><i class="icon icon-arrow-left"></i> Voltar</router-link>

      <div class="mt-2 pt-2 text-right">
        <button @click="isDeleteModalOpen = true" class="btn btn-sm btn-error text-normal"><i class="icon icon-stop mr-1"></i> Reverter</button>
      </div>
    </div>
  </div>

  <div class="card card-condensed">
    <div class="card-header">
      <div class="card-title h5">{{ maintenance.number }}</div>
      <div class="card-subtitle pb-2">
        <router-link :to="`/clients/${maintenance.place.client_id}/places/${maintenance.place.id}`">({{ maintenance.place.number }}) {{ maintenance.place.name }}</router-link>
      </div>

      <div v-if="maintenance.external_service" class="columns my-2">
        <div class="column w-2/12 text-gray-dark">Fonte</div>
        <div class="column w-10/12">{{ maintenance.external_service }}</div>
      </div>

      <!-- <div v-if="maintenance.external_id" class="columns my-2 pb-2">
        <div class="column col-2 text-gray-dark">ID Externo</div>
        <div class="column col-10">{{ maintenance.external_id }}</div>
      </div> -->

      <!-- <div class="columns my-2">
        <div class="column col-2 text-gray-dark">Data de Criação</div>
        <div class="column col-10">{{ maintenance.created_at | date }}</div>
      </div> -->

      <div class="columns my-2">
        <div class="column w-2/12 text-gray-dark">Data do Serviço</div>
        <div class="column w-10/12">{{ maintenance.serviced_at ? maintenance.serviced_at : '-' | date }}</div>
      </div>

      <hr>
    </div>
    <div class="card-body">
      <table class="table table-striped table-hover">
        <thead>
          <th>No.</th>
          <th>Tipo</th>
          <th class="hidden lg:table-cell">Serviço Efetuado</th>
          <th>Estado</th>
        </thead>
        <tbody>
          <tr v-for="(equipment, index) in maintenance.equipments" :key="index">
            <td>{{ equipment.number }}</td>
            <td>{{ equipment.equipment_type.full_name }}</td>
            <td class="hidden lg:table-cell">{{ $utils.maintenanceAction(equipment.pivot.status) }}</td>
            <td>{{ $utils.equipmentStatus(equipment) }}</td>
          </tr>
          <tr v-if="maintenance.equipments.length === 0">
            <td class="text-center text-gray-dark" colspan="99">Não há equipamentos na manutenção.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <Modal :is-open="isDeleteModalOpen" @close="isDeleteModalOpen = false" @confirm="deleteMaintenance">
    <template slot="title">Atenção!</template>
    <template>
      <div class="flex-centered mb-2 pb-2 text-danger text-error">
        <i class="icon icon-4x icon-delete"></i>
      </div>
      <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
      <p class="mb-0 text-gray-darker">Está prestes a <strong>reverter uma manutenção</strong>, tem a certeza?</p>
    </template> 
    <template slot="actions" slot-scope="{ close, confirm }">
      <button type="button" class="btn mr-2" @click="close">Cancelar</button>
      <button type="button" class="btn btn-error" @click="confirm">Confirmar</button>
    </template>
  </Modal>
</div>
</template>

<script>
import Modal from '../../components/Modal'
import { getMaintenance, deleteMaintenance } from '../../api/'

export default {

  components: {
    Modal
  },

  data() {
    return {
      maintenance: null,
      isDeleteModalOpen: false,
    }
  },

  computed: {
    maintenanceId() {
      return this.$route.params.maintenance
    },
  },

  created() {
    this.fetchMaintenance()
  },

  methods: {

    fetchMaintenance() {
      getMaintenance(this.maintenanceId).then(({ data }) => {
        this.maintenance = data.data
      }).catch(() => {
        this.$router.push('/404')
      })
    },

    deleteMaintenance() {
      deleteMaintenance(this.maintenanceId).then(() => {
        this.isDeleteModalOpen = false
        this.$router.push(`/maintenances?deleted=true`)
      })
    },

  },

}
</script>
