import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import NotFound from "./views/NotFound.vue";
import Login from "./views/Login.vue";
import MyProfile from "./views/MyProfile.vue";
import Lists from "./views/Lists.vue";
import NearbyList from "./views/NearbyList.vue";
import ListMaintenances from "./views/Maintenances/ListMaintenances.vue";
import CreateMaintenance from "./views/Maintenances/CreateMaintenance.vue";
import ViewMaintenance from "./views/Maintenances/ViewMaintenance.vue";
import ListClients from "./views/Clients/ListClients.vue";
import CreateClient from "./views/Clients/CreateClient.vue";
import ViewClient from "./views/Clients/ViewClient.vue";
import EditClient from "./views/Clients/EditClient.vue";
import CreatePlace from "./views/Places/CreatePlace.vue";
import ViewPlace from "./views/Places/ViewPlace.vue";
import EditPlace from "./views/Places/EditPlace.vue";
import ListEquipmentTypes from "./views/EquipmentTypes/ListEquipmentTypes.vue";
import CreateEquipmentType from "./views/EquipmentTypes/CreateEquipmentType.vue";
import EditEquipmentType from "./views/EquipmentTypes/EditEquipmentType.vue";
import ListZones from "./views/Zones/ListZones.vue";
import CreateZone from "./views/Zones/CreateZone.vue";
import EditZone from "./views/Zones/EditZone.vue";
import Moloni from "./views/Services/Moloni.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      localStorage.setItem("savedScroll", JSON.stringify(savedPosition));
    } else {
      localStorage.setItem("savedScroll", JSON.stringify({ x: 0, y: 0 }));
    }
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", name: "home", component: Home },
    { path: "/login", name: "login", component: Login },
    { path: "/dashboard", redirect: "/lists" },
    { path: "/lists", component: Lists },
    { path: "/nearby", component: NearbyList },
    { path: "/maintenances", component: ListMaintenances },
    { path: "/maintenances/create", component: CreateMaintenance },
    { path: "/maintenances/:maintenance", component: ViewMaintenance },
    { path: "/clients", component: ListClients },
    { path: "/clients/create", component: CreateClient },
    { path: "/clients/:client/edit", component: EditClient },
    { path: "/clients/:client", component: ViewClient },
    { path: "/clients/:client/places/create", component: CreatePlace },
    { path: "/clients/:client/places/:place/edit", component: EditPlace },
    { path: "/clients/:client/places/:place", component: ViewPlace },
    { path: "/settings/equipment-types", component: ListEquipmentTypes },
    {
      path: "/settings/equipment-types/create",
      component: CreateEquipmentType,
    },
    {
      path: "/settings/equipment-types/:equipmentType/edit",
      component: EditEquipmentType,
    },
    { path: "/settings/zones", component: ListZones },
    { path: "/settings/zones/create", component: CreateZone },
    { path: "/settings/zones/:zone/edit", component: EditZone },
    { path: "/settings/moloni", component: Moloni },
    { path: "/settings", redirect: "/settings/equipment-types" },
    { path: "/my-profile", component: MyProfile },
    { path: "*", component: NotFound },
  ],
});
