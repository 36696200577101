<template>
  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="container">
    <div class="columns col-gapless mb-2">
      <div class="column col-6">
        <h2 class="text-xl lg:text-2xl">Locais</h2>
      </div>
      <div class="column col-6 text-right">
        <a
          class="btn btn-link"
          @click="(_) => $utils.goBack($router, `/clients/${clientId}`)"
          ><i class="icon icon-arrow-left"></i> Voltar</a
        >
      </div>
    </div>

    <div class="columns col-gapless mb-2">
      <div class="column col-8">
        <ul class="breadcrumb text-xs">
          <li class="breadcrumb-item">
            <router-link to="/clients">Clientes</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/clients/${clientId}`">{{
              place.client.name
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/clients/${clientId}/places/${place.id}`">{{
              place.number
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="column col-4 flex justify-end items-center">
          <router-link
            class="btn btn-sm"
            :to="`/clients/${clientId}/places/${place.id}/edit`"
          >
            <i class="icon icon-edit mr-1"></i> Editar
          </router-link>
      </div>
    </div>

    <div class="card card-condensed card-table">
      <div class="card-body">
        <PlaceMap :place="place" style="height: 240px;" />
        <table class="table">
          <thead>
            <tr>
              <th colspan="2" class="my-2">
                {{ place.name }} <small>({{ place.number }})</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="150" class="text-bold">NIF</td>
              <td>{{ place.vat }}</td>
            </tr>
            <tr>
              <td class="text-bold">Contacto</td>
              <td>{{ place.phone }}</td>
            </tr>
            <tr>
              <td class="text-bold">Email</td>
              <td>{{ place.email }}</td>
            </tr>
            <tr>
              <td class="text-bold">Observações</td>
              <td>{{ place.notes }}</td>
            </tr>
            <tr>
              <td class="text-bold">Endereço</td>
              <td>
                {{ place.full_address }}
                <template v-if="place.coordinates">
                  ({{ place.coordinates.lat }},
                  {{ place.coordinates.lng }})</template
                >
              </td>
            </tr>
            <tr>
              <td class="text-bold">Zona</td>
              <td>{{ place.zone ? place.zone.name : "SEM ZONA" }}</td>
            </tr>
            <tr>
              <td class="text-bold">Equipamentos</td>
              <td>{{ orderedEquipments.length }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <br />

    <div class="columns col-gapless mb-2">
      <div class="column col-6">
        <h4 class="text-xl">Equipamentos</h4>
      </div>
      <div class="column col-6 text-right">
        <button @click="openNewEquipment" class="btn btn-primary">
          Novo Equipamento
        </button>
      </div>
    </div>

    <div class="flex lg:justify-end justify-around space-x-2">
      <button
        @click="disableEquipments"
        class="btn btn-default"
        :class="{ disabled: checkedEquipments.length == 0 }"
      >
        Desligar Gestão
      </button>
      <button
        @click="toEditLastMaintenanceAt = true"
        class="btn btn-default"
        :class="{ disabled: checkedEquipments.length == 0 }"
      >
        Alterar Data Última Manutenção
      </button>
    </div>

    <div class="card card-condensed mt-2">
      <div class="card-body">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <label
                  class="form-checkbox"
                  @click.prevent="toggleCheckedEquipments"
                >
                  <input ref="checkboxAllEquipments" type="checkbox" />
                  <i class="form-icon"></i>
                </label>
              </th>
              <th>No.</th>
              <th>Tipo</th>
              <th>Data de Fabrico</th>
              <th>Última Manutenção</th>
              <th>Última Recarga</th>
              <th>Próxima Revisão</th>
              <th>Estado</th>
              <th>Gestão?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="newEquipment">
              <td></td>
              <td>
                <div
                  class="form-group"
                  :class="{ 'has-error': newEquipmentErrors.number }"
                >
                  <input
                    type="text"
                    class="form-input input-sm"
                    v-model="newEquipment.number"
                  />
                </div>
              </td>
              <td>
                <div
                  class="form-group"
                  :class="{ 'has-error': newEquipmentErrors.equipment_type_id }"
                >
                  <select
                    v-model="newEquipment.equipment_type_id"
                    class="form-select select-sm"
                  >
                    <option value="" disabled>Escolher tipo</option>
                    <option
                      v-for="equipmentType in equipmentTypes"
                      :key="equipmentType.id"
                      :value="equipmentType.id"
                      >{{ equipmentType.full_name }}</option
                    >
                  </select>
                </div>
              </td>
              <td>
                <div
                  class="form-group"
                  :class="{ 'has-error': newEquipmentErrors.fabricated_at }"
                >
                  <input
                    type="text"
                    class="form-input input-sm"
                    placeholder="YYYY-MM-DD"
                    v-model="newEquipment.fabricated_at"
                  />
                </div>
              </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="text-right" style="min-width: 92px;">
                <button
                  @click="closeNewEquipment"
                  class="btn btn-action btn-sm mr-1"
                >
                  <i class="icon icon-cross"></i>
                </button>
                <button
                  @click="createNewEquipment"
                  class="btn btn-primary btn-action btn-sm"
                >
                  <i class="icon icon-plus"></i>
                </button>
              </td>
            </tr>
            <tr v-for="equipment in orderedEquipments" :key="equipment.id">
              <template
                v-if="editEquipment && editEquipment.id === equipment.id"
              >
                <td></td>
                <td>
                  <div
                    class="form-group"
                    :class="{ 'has-error': editEquipmentErrors.number }"
                  >
                    <input
                      type="text"
                      class="form-input input-sm"
                      v-model="editEquipment.number"
                    />
                  </div>
                </td>
                <td>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': editEquipmentErrors.equipment_type_id,
                    }"
                  >
                    <select
                      v-model="editEquipment.equipment_type_id"
                      class="form-select select-sm"
                    >
                      <option value="" disabled>Escolher tipo</option>
                      <option
                        v-for="equipmentType in equipmentTypes"
                        :key="equipmentType.id"
                        :value="equipmentType.id"
                        >{{ equipmentType.full_name }}</option
                      >
                    </select>
                  </div>
                </td>
                <td>
                  <div
                    class="form-group"
                    :class="{ 'has-error': editEquipmentErrors.fabricated_at }"
                  >
                    <input
                      type="text"
                      class="form-input input-sm"
                      placeholder="YYYY-MM-DD"
                      v-model="editEquipment.fabricated_at"
                    />
                  </div>
                </td>
                <td>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': editEquipmentErrors.last_maintenance_at,
                    }"
                  >
                    <input
                      type="text"
                      class="form-input input-sm"
                      placeholder="YYYY-MM-DD"
                      v-model="editEquipment.last_maintenance_at"
                    />
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td>{{ $utils.equipmentStatus(editEquipment) }}</td>
                <td>
                  <label class="form-switch">
                    <input type="checkbox" v-model="editEquipment.enabled" />
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td class="text-right" style="min-width: 92px;">
                  <button
                    @click="closeEditEquipment"
                    class="btn btn-action btn-sm mr-1"
                  >
                    <i class="icon icon-cross"></i>
                  </button>
                  <button
                    @click="saveEditEquipment"
                    class="btn btn-primary btn-action btn-sm"
                  >
                    <i class="icon icon-check"></i>
                  </button>
                </td>
              </template>
              <template v-else>
                <td>
                  <label class="form-checkbox">
                    <input
                      type="checkbox"
                      :value="equipment.id"
                      v-model="checkedEquipments"
                    />
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td>{{ equipment.number }}</td>
                <td>{{ equipment.equipment_type.full_name }}</td>
                <td>{{ equipment.fabricated_at | date }}</td>
                <td>{{ equipment.last_maintenance_at | date }}</td>
                <td>
                  {{
                    equipment.equipment_type.type == "Extinguisher"
                      ? equipment.last_recharge_at
                      : null | date
                  }}
                </td>
                <td>{{ equipment.next_maintenance_at | dateForHumans }}</td>
                <td>{{ $utils.equipmentStatus(equipment) }}</td>
                <td>{{ equipment.enabled ? "Sim" : "Não" }}</td>
                <td class="text-right" style="min-width: 92px;">
                  <button
                    @click="openEditEquipment(equipment)"
                    class="btn btn-action btn-sm mr-1"
                  >
                    <i class="icon icon-edit"></i>
                  </button>
                  <button
                    @click="openDeleteEquipment(equipment)"
                    class="btn btn-error btn-sm"
                  >
                    <i class="icon icon-delete"></i>
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <portal to="toasts">
      <div v-if="disableEquipmentsSuccess" class="toast toast-success mb-2">
        <button
          type="button"
          @click="disableEquipmentsSuccess = false"
          class="btn btn-clear float-right"
        ></button>
        Gestão de equipamentos desligada com successo!
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="disableEquipmentsError" class="toast toast-error mb-2">
        <button
          type="button"
          @click="disableEquipmentsError = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a desligar a gestão de equipamentos.
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="editLastMaintenanceAtSuccess" class="toast toast-success mb-2">
        <button
          type="button"
          @click="editLastMaintenanceAtSuccess = false"
          class="btn btn-clear float-right"
        ></button>
        Data da última manutenção alterada com successo!
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="editLastMaintenanceAtError" class="toast toast-error mb-2">
        <button
          type="button"
          @click="editLastMaintenanceAtError = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a tentar guardar a data da última
        manutenção.
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="editEquipmentError" class="toast toast-error mb-2">
        <button
          type="button"
          @click="editEquipmentError = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a tentar guardar o equipamento.
        <ul>
          <li v-for="(errors, key) in editEquipmentErrors" :key="key">
            {{ errors[0] }}
          </li>
        </ul>
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="newEquipmentError" class="toast toast-error mb-2">
        <button
          type="button"
          @click="newEquipmentError = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a tentar criar o equipamento.
        <ul>
          <li v-for="(errors, key) in newEquipmentErrors" :key="key">
            {{ errors[0] }}
          </li>
        </ul>
      </div>
    </portal>

    <portal to="toasts">
      <div v-if="deleteEquipmentError" class="toast toast-error mb-2">
        <button
          type="button"
          @click="deleteEquipmentError = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a tentar eliminar o equipamento.
      </div>
    </portal>

    <Modal
      :is-open="!!toDeleteEquipment"
      @close="toDeleteEquipment = null"
      @confirm="deleteEquipment"
    >
      <template slot="title"
        >Atenção!</template
      >
      <template>
        <div class="flex-centered mb-2 pb-2 text-danger text-error">
          <i class="icon icon-4x icon-delete"></i>
        </div>
        <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
        <p class="mb-0 text-gray-darker">
          Está prestes a
          <strong
            >eliminar um equipamento (#{{
              toDeleteEquipment ? toDeleteEquipment.number : ""
            }})</strong
          >, tem a certeza?
        </p>
      </template>
      <template slot="actions" slot-scope="{ close, confirm }">
        <button type="button" class="btn mr-2" @click="close">Cancelar</button>
        <button type="button" class="btn btn-error" @click="confirm">
          Confirmar
        </button>
      </template>
    </Modal>

    <Modal
      :is-open="toEditLastMaintenanceAt"
      @close="toEditLastMaintenanceAt = false"
      @confirm="updateLastMaintenanceAt"
    >
      <template slot="title"
        >Alterar Data Última Manutenção</template
      >
      <template>
        <div
          class="form-group"
          :class="{ 'has-error': editLastMaintenanceAtError }"
        >
          <input
            type="text"
            class="form-input input-sm"
            placeholder="YYYY-MM-DD"
            v-model="editLastMaintenanceAt"
          />
        </div>
        <p class="mb-0 text-gray-darker">
          Está prestes a alterar a data da última manutenção de
          <strong
            >{{ checkedEquipments.length }} equipamento{{
              checkedEquipments.length > 1 ? "s" : ""
            }}</strong
          >.
        </p>
      </template>
      <template slot="actions" slot-scope="{ close, confirm }">
        <button type="button" class="btn mr-2" @click="close">Cancelar</button>
        <button
          type="button"
          class="btn btn-error"
          :class="{ disabled: !editLastMaintenanceAt }"
          @click="confirm"
        >
          Confirmar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../components/Modal";
import PlaceMap from "../../components/PlaceMap";
import {
  getPlace,
  getEquipmentTypes,
  createEquipment,
  updateEquipment,
  updateLastMaintenanceAt,
  disableEquipments,
  deleteEquipment,
} from "../../api/";

export default {
  components: {
    Modal,
    PlaceMap,
  },

  data() {
    return {
      place: null,
      equipmentTypes: null,
      newEquipment: null,
      newEquipmentError: false,
      newEquipmentErrors: {},
      editEquipment: null,
      editEquipmentError: false,
      editEquipmentErrors: {},
      toDeleteEquipment: null,
      deleteEquipmentError: false,
      deleteEquipmentErrors: {},
      checkedEquipments: [],
      toEditLastMaintenanceAt: false,
      editLastMaintenanceAt: null,
      editLastMaintenanceAtError: false,
      editLastMaintenanceAtSuccess: false,
      disableEquipmentsError: false,
      disableEquipmentsSuccess: false,
    };
  },

  computed: {
    loading() {
      return !this.place || !this.equipmentTypes;
    },

    clientId() {
      return this.$route.params.client;
    },

    placeId() {
      return this.$route.params.place;
    },

    orderedEquipments() {
      return this.place.equipments
        ? this.place.equipments.slice().sort((a, b) => b.number - a.number)
        : [];
    },
  },

  watch: {
    checkedEquipments(checkedEquipments) {
      this.$refs.checkboxAllEquipments.indeterminate = false;
      if (checkedEquipments.length == this.place.equipments.length) {
        this.$refs.checkboxAllEquipments.checked = true;
      } else {
        this.$refs.checkboxAllEquipments.checked = false;
        if (checkedEquipments.length > 0) {
          this.$refs.checkboxAllEquipments.indeterminate = true;
        }
      }
    },
  },

  created() {
    this.fetchEquipmentTypes();
    this.fetchPlace();
  },

  methods: {
    disableEquipments() {
      disableEquipments(this.checkedEquipments)
        .then(({ data: { data: equipments } }) => {
          this.place.equipments = this.place.equipments.map((e) => {
            const equipment = equipments.find(
              (equipment) => equipment.id == e.id
            );
            if (equipment) return equipment;
            return e;
          });
          this.checkedEquipments = [];
          this.disableEquipmentsSuccess = true;
        })
        .catch(() => {
          this.disableEquipmentsError = true;
        });
    },

    updateLastMaintenanceAt() {
      updateLastMaintenanceAt(
        this.checkedEquipments,
        this.editLastMaintenanceAt
      )
        .then(({ data: { data: equipments } }) => {
          this.place.equipments = this.place.equipments.map((e) => {
            const equipment = equipments.find(
              (equipment) => equipment.id == e.id
            );
            if (equipment) return equipment;
            return e;
          });
          this.checkedEquipments = [];
          this.toEditLastMaintenanceAt = false;
          this.editLastMaintenanceAtSuccess = true;
        })
        .catch(() => {
          this.editLastMaintenanceAtError = true;
        });
    },

    toggleCheckedEquipments() {
      if (this.checkedEquipments.length > 0) {
        this.checkedEquipments = [];
      } else {
        this.checkedEquipments = this.orderedEquipments
          .slice()
          .map((e) => e.id);
      }
    },

    fetchEquipmentTypes() {
      getEquipmentTypes()
        .then(({ data }) => {
          this.equipmentTypes = data.data;
        })
        .catch(() => {
          this.equipmentTypes = [];
        });
    },

    fetchPlace() {
      getPlace(this.placeId)
        .then(({ data }) => {
          this.place = data.data;
        })
        .catch(() => {
          this.$router.push("/404");
        });
    },

    openNewEquipment() {
      this.newEquipment = {
        number: "",
        equipment_type_id: "",
        fabricated_at: "",
      };
    },

    closeNewEquipment() {
      this.newEquipment = null;
    },

    createNewEquipment() {
      this.newEquipmentError = false;
      this.newEquipmentErrors = {};

      createEquipment(this.placeId, this.newEquipment)
        .then(({ data }) => {
          const equipment = data.data;
          this.place.equipments.push(equipment);
          this.closeNewEquipment();
        })
        .catch(({ response }) => {
          this.newEquipmentError = true;
          this.newEquipmentErrors = response.data.errors;
        });
    },

    openEditEquipment(equipment) {
      this.editEquipment = Object.assign({}, equipment);
    },

    closeEditEquipment() {
      this.editEquipment = null;
    },

    saveEditEquipment() {
      this.editEquipmentError = false;
      this.editEquipmentErrors = {};

      updateEquipment(this.editEquipment.id, this.editEquipment)
        .then(({ data }) => {
          const equipment = data.data;
          this.place.equipments = this.place.equipments.map((e) => {
            if (e.id === equipment.id) return equipment;
            return e;
          });
          this.closeEditEquipment();
        })
        .catch(({ response }) => {
          this.editEquipmentError = true;
          this.editEquipmentErrors = response.data.errors;
        });
    },

    openDeleteEquipment(equipment) {
      this.toDeleteEquipment = equipment;
    },

    deleteEquipment() {
      deleteEquipment(this.toDeleteEquipment.id)
        .then(() => {
          this.place.equipments = this.place.equipments.filter(
            (e) => e.id !== this.toDeleteEquipment.id
          );
          this.toDeleteEquipment = null;
        })
        .catch(({ response }) => {
          this.toDeleteEquipment = null;
          this.deleteEquipmentError = true;
          this.deleteEquipmentErrors = response.data.errors;
        });
    },
  },
};
</script>
