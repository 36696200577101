<template>
<div v-if="! client" class="hero">
    <div class="loading loading-lg"></div>
</div>
<div v-else class="container">
  <div class="columns col-gapless">
    <div class="column col-6">
      <h2 class="text-xl lg:text-2xl mb-2">Clientes</h2>

      <ul class="breadcrumb text-xs">
        <li class="breadcrumb-item">
          <router-link to="/clients">Clientes</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="`/clients/${client.id}`">{{ client.number }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="`/clients/${client.id}/edit`">Editar</router-link>
        </li>
      </ul>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-link mb-2 pb-2" :to="`/clients/${client.id}`"><i class="icon icon-arrow-left"></i> Voltar</router-link>

      <div class="text-right">
        <button @click="isDeleteModalOpen = true" class="btn btn-sm btn-error text-normal"><i class="icon icon-delete mr-1"></i> Eliminar</button>
      </div>
    </div>
  </div>

    <div class="card">
      <div class="card-body">
        
      <form @submit.prevent="saveClient" class="form-horizontal px-2">
        <div class="form-group" :class="{'has-error': errors.number}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Número</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="editClient.number">
            <p v-if="errors.number" class="form-input-hint mb-1">{{ errors.number[0] }}</p>
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.name}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Nome</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="editClient.name">
            <p v-if="errors.name" class="form-input-hint mb-1">{{ errors.name[0] }}</p>
          </div>
        </div>

        <div class="form-group" :class="{'has-error': errors.vat}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">NIF</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="number" v-model="editClient.vat">
            <p v-if="errors.vat" class="form-input-hint mb-1">{{ errors.vat[0] }}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Endereço</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="editClient.address">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Contacto</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="tel" v-model="editClient.phone">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Email</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="email" pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" v-model="editClient.email">
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Observações</label>
          </div>
          <div class="w-full lg:w-9/12">
            <textarea class="form-input" v-model="editClient.notes"></textarea>
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12"></div>
          <div class="w-full lg:w-9/12">
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </div>

        <portal to="toasts">
          <div v-if="success" class="toast toast-success mb-2">
            <button type="button" @click="success = false" class="btn btn-clear float-right"></button>
            O cliente foi alterado com <strong>sucesso</strong>!
          </div>
        </portal>

        <portal to="toasts">
          <div v-if="error" class="toast toast-error mb-2">
            <button type="button" @click="error = false" class="btn btn-clear float-right"></button>
            Ocorreu um <strong>erro</strong> a tentar guardar o cliente.
          </div>
        </portal>
      </form>
    </div>
  </div>

  <Modal :is-open="isDeleteModalOpen" @close="isDeleteModalOpen = false" @confirm="deleteClient">
    <template slot="title">Atenção!</template>
    <template>
      <div class="flex-centered mb-2 pb-2 text-danger text-error">
        <i class="icon icon-4x icon-delete"></i>
      </div>
      <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
      <p class="mb-0 text-gray-darker">Está prestes a <strong>eliminar um cliente</strong>, tem a certeza?</p>
    </template> 
    <template slot="actions" slot-scope="{ close, confirm }">
      <button type="button" class="btn mr-2" @click="close">Cancelar</button>
      <button type="button" class="btn btn-error" @click="confirm">Confirmar</button>
    </template>
  </Modal>
</div>
</template>

<script>
import Modal from '../../components/Modal'
import { getClient, updateClient, deleteClient } from '../../api/'

export default {

  components: {
    Modal,
  },

  data() {
    return {
      client: null,
      editClient: null,
      success: false,
      error: false,
      errors: {},
      isDeleteModalOpen: false,
    }
  },

  computed: {
    clientId() {
      return this.$route.params.client
    },
  },

  created() {
    this.fetchClient()
  },

  methods: {

    fetchClient() {
      getClient(this.clientId).then(({ data }) => {
        this.client = data.data
        this.editClient = Object.assign({}, data.data)
        this.editClient.number = parseFloat(this.editClient.number)
      }).catch(() => {
        this.$router.push('/404')
      })
    },

    saveClient() {
      this.error = false
      this.errors = {}

      updateClient(this.clientId, this.editClient).then(({ data }) => {
        this.client = data.data
        this.editClient = Object.assign({}, data.data)
        this.editClient.number = parseFloat(this.editClient.number)
        this.success = true
        setTimeout(() => {
          this.success = false
        }, 2000)
      }).catch(({ response }) => {
        this.error = true
        this.errors = response.data.errors
      })
    },

    deleteClient() {
      deleteClient(this.clientId).then(() => {
        this.isDeleteModalOpen = false
        this.$router.push("/clients?deleted=true")
      })
    },

  },

}
</script>
