<template>
  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="container">
    <div class="columns col-gapless mb-2">
      <div class="column col-6">
        <h2 class="text-2xl">Locais</h2>
      </div>
      <div class="column col-6 text-right">
        <router-link
          class="btn btn-link"
          :to="`/clients/${clientId}/places/${place.id}`"
        >
          <i class="icon icon-arrow-left"></i> Voltar
        </router-link>
      </div>
    </div>

    <ul class="breadcrumb text-xs">
      <li class="breadcrumb-item">
        <router-link to="/clients">Clientes</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/clients/${clientId}`">{{
          place.client.name
        }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/clients/${clientId}/places/${place.id}`">{{
          place.number
        }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="`/clients/${clientId}/places/${place.id}/edit`"
          >Editar</router-link
        >
      </li>
    </ul>

    <div class="mb-2 text-right">
      <button
        @click="isDeleteModalOpen = true"
        class="btn btn-sm btn-error text-normal"
      >
        <i class="icon icon-delete mr-1"></i> Eliminar
      </button>
    </div>

    <div class="card">
      <div class="card-body">
        <form @submit.prevent="savePlace" class="form-horizontal px-2">
          <div class="form-group" :class="{ 'has-error': errors.number }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Número</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="editPlace.number"
              />
              <p v-if="errors.number" class="form-input-hint mb-1">
                {{ errors.number[0] }}
              </p>
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.name }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Nome</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="editPlace.name" />
              <p v-if="errors.name" class="form-input-hint mb-1">
                {{ errors.name[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">NIF</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="number" v-model="editPlace.vat" />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Contacto</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="tel" v-model="editPlace.phone" />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Email</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="email"
                pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                v-model="editPlace.email"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Observações</label>
            </div>
            <div class="w-full lg:w-9/12">
              <textarea class="form-input" v-model="editPlace.notes"></textarea>
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.address }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Endereço</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="editPlace.address"
              />
              <p v-if="errors.address" class="form-input-hint mb-1">
                {{ errors.address[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Código postal</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="editPlace.zip" />
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': errors.city }">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Localidade</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input class="form-input" type="text" v-model="editPlace.city" />
              <p v-if="errors.city" class="form-input-hint mb-1">
                {{ errors.city[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12"></div>
            <div class="w-full lg:w-9/12">
              <EditableMap
                :coordinates="editPlace.coordinates"
                style="height: 225px"
                @change="updateLatLng"
              ></EditableMap>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Latitude</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="editPlace.coordinates.lat"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12">
              <label class="form-label">Longitude</label>
            </div>
            <div class="w-full lg:w-9/12">
              <input
                class="form-input"
                type="text"
                v-model="editPlace.coordinates.lng"
              />
            </div>
          </div>

          <div
            class="form-group"
            :class="{ 'has-error': errors.zone_id || errors.zone }"
          >
            <div class="w-full lg:w-3/12">
              <label class="form-label">Zona</label>
            </div>
            <div class="w-full lg:w-9/12">
              <select
                class="form-select"
                type="text"
                v-model="editPlace.zone_id"
              >
                <option :value="null">Escolher zona</option>
                <option value="NEW">Criar nova zona</option>
                <option
                  v-for="zone in orderedZones"
                  :value="zone.id"
                  :key="zone.id"
                  >{{ zone.name }}</option
                >
              </select>
              <p v-if="errors.zone_id" class="form-input-hint mb-1">
                {{ errors.zone_id[0] }}
              </p>
              <input
                v-if="editPlace.zone_id == 'NEW'"
                class="mt-1 form-input"
                type="text"
                v-model="editPlace.zone_name"
              />
              <p v-if="errors.zone" class="form-input-hint mb-1">
                {{ errors.zone[0] }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="w-full lg:w-3/12"></div>
            <div class="w-full lg:w-9/12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>

          <portal to="toasts">
            <div v-if="success" class="toast toast-success mb-2">
              <button
                type="button"
                @click="success = false"
                class="btn btn-clear float-right"
              ></button>
              O local foi alterado com
              <strong>sucesso</strong>!
            </div>
          </portal>

          <portal to="toasts">
            <div v-if="error" class="toast toast-error mb-2">
              <button
                type="button"
                @click="error = false"
                class="btn btn-clear float-right"
              ></button>
              Ocorreu um
              <strong>erro</strong> a tentar guardar o local.
            </div>
          </portal>
        </form>
      </div>
    </div>

    <Modal
      :is-open="isDeleteModalOpen"
      @close="isDeleteModalOpen = false"
      @confirm="deletePlace"
    >
      <template slot="title"
        >Atenção!</template
      >
      <template>
        <div class="flex-centered mb-2 pb-2 text-danger text-error">
          <i class="icon icon-4x icon-delete"></i>
        </div>
        <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
        <p class="mb-0 text-gray-darker">
          Está prestes a
          <strong>eliminar um local</strong>, tem a certeza?
        </p>
      </template>
      <template slot="actions" slot-scope="{ close, confirm }">
        <button type="button" class="btn mr-2" @click="close">Cancelar</button>
        <button type="button" class="btn btn-error" @click="confirm">
          Confirmar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../components/Modal";
import EditableMap from "../../components/EditableMap";
import { getPlace, updatePlace, deletePlace, getZones } from "../../api/";

export default {
  components: {
    Modal,
    EditableMap,
  },

  data() {
    return {
      zones: null,
      place: null,
      editPlace: null,
      success: false,
      error: false,
      errors: {},
      isDeleteModalOpen: false,
    };
  },

  computed: {
    clientId() {
      return this.$route.params.client;
    },

    placeId() {
      return this.$route.params.place;
    },

    loading() {
      return !this.place || !this.zones;
    },

    orderedZones() {
      if (!this.zones) return [];
      return this.zones.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  created() {
    this.fetchZones();
    this.fetchPlace();
  },

  methods: {
    fetchZones() {
      getZones().then(({ data }) => {
        this.zones = data.data;
      });
    },

    updateLatLng({ coordinates }) {
      this.editPlace.coordinates = coordinates;
    },

    fetchPlace() {
      getPlace(this.placeId)
        .then(({ data }) => {
          this.place = data.data;
          this.editPlace = Object.assign({}, data.data);
          this.editPlace.number = this.$utils.ltrim(this.editPlace.number, "0");
          if (!this.editPlace.coordinates) {
            this.editPlace.coordinates = { lat: null, lng: null };
          }
        })
        .catch(() => {
          this.$router.push("/404");
        });
    },

    savePlace() {
      this.error = false;
      this.errors = {};

      updatePlace(this.placeId, this.editPlace)
        .then(({ data }) => {
          this.place = Object.assign({}, this.place, data.data);
          this.editPlace = Object.assign({}, this.editPlace, data.data);
          this.editPlace.number = this.$utils.ltrim(this.editPlace.number, "0");
          if (!this.editPlace.coordinates) {
            this.editPlace.coordinates = { lat: null, lng: null };
          }
          if (this.editPlace.zone_name) {
            this.fetchZones();
          }
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
        })
        .catch(({ response }) => {
          this.error = true;
          this.errors = response.data.errors;
        });
    },

    deletePlace() {
      deletePlace(this.placeId).then(() => {
        this.isDeleteModalOpen = false;
        this.$router.push(`/clients/${this.clientId}?deleted=true`);
      });
    },
  },
};
</script>
