<template>
<div class="container">
  <SettingsNav />

  <div class="columns col-gapless mb-2">
    <div class="column col-8">
      <h2 class="text-xl lg:text-2xl mb-2">Tipos de Extintor</h2>
    </div>
    <div class="column col-4 text-right">
      <router-link class="btn btn-primary" to="/settings/equipment-types/create"><i class="icon icon-flag"></i> Novo Tipo</router-link>
    </div>
  </div>

  <div v-if="! orderedExtinguisherTypes" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="card card-condensed card-table">
    <div class="card-body">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th class="hidden md:table-cell">Descrição</th>
            <th class="md:hidden">Períodos <br /> (Meses)</th>
            <th class="hidden md:table-cell">Período de Manutenção <br /> (Meses)</th>
            <th class="hidden md:table-cell">Período de Recarga <br /> (Meses)</th>
            <th class="hidden md:table-cell">Tempo de Vida <br /> (Meses)</th>
            <th class="hidden md:table-cell">Referências</th>
          </tr>
        </thead>
        <tbody>
          <tr class="c-hand" v-for="equipmentType in orderedExtinguisherTypes" @click="goToEquipmentType(equipmentType)" :key="equipmentType.id">
            <td>
              {{ equipmentType.name }}
              <p class="text-xs text-gray-500">{{ equipmentType.description }}</p>
            </td>
            <td class="hidden md:table-cell">{{ equipmentType.description }}</td>
            <td class="md:hidden text-xs">
              <span><strong>Período de Manutenção:</strong> {{ equipmentType.maintenance_period }} <br /></span>
              <span><strong>Período de Recarga:</strong> {{ equipmentType.recharge_period }} <br /></span>
              <span><strong>Tempo de Vida:</strong> {{ equipmentType.life_period }} <br /></span>
            </td>
            <td class="hidden md:table-cell">{{ equipmentType.maintenance_period }}</td>
            <td class="hidden md:table-cell">{{ equipmentType.recharge_period }}</td>
            <td class="hidden md:table-cell">{{ equipmentType.life_period }}</td>
            <td class="hidden md:table-cell">
              <span v-if="equipmentType.maintenance_reference"><strong>Manutenção:</strong> {{ equipmentType.maintenance_reference }} <br /></span>
              <span v-if="equipmentType.recharge_reference"><strong>Recarga:</strong> {{ equipmentType.recharge_reference }} <br /></span>
              <span v-if="equipmentType.destroy_reference"><strong>Abatido:</strong> {{ equipmentType.destroy_reference }} <br /></span>
              <span v-if="equipmentType.new_reference"><strong>Novo:</strong> {{ equipmentType.new_reference }} <br /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="columns col-gapless pt-2 mt-2 mb-2">
    <div class="column col-12">
      <h2 class="text-xl lg:text-2xl mb-2">Outros Tipos de Equipamento</h2>
    </div>
  </div>

  <div v-if="! orderedOtherEquipmentTypes" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="card card-condensed card-table">
    <div class="card-body">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Período de Manutenção <br /> (Meses)</th>
            <th class="hidden md:table-cell">Referências</th>
          </tr>
        </thead>
        <tbody>
          <tr class="c-hand" v-for="equipmentType in orderedOtherEquipmentTypes" @click="goToEquipmentType(equipmentType)" :key="equipmentType.id">
            <td>{{ equipmentType.name }}</td>
            <td>{{ equipmentType.maintenance_period }}</td>
            <td class="hidden md:table-cell">
              <span v-if="equipmentType.maintenance_reference"><strong>Manutenção:</strong> {{ equipmentType.maintenance_reference }} <br /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <portal to="toasts">
    <div v-if="created" class="toast toast-success mb-2">
      <button type="button" @click="created = false" class="btn btn-clear float-right"></button>
      O tipo de equipamento foi criado com <strong>sucesso</strong>!
    </div>
  </portal>
</div>
</template>

<script>
import moment from 'moment'
import { getEquipmentTypes } from '../../api/'
import SettingsNav from '../../components/SettingsNav'

export default {

  components: {
    SettingsNav,
  },

  data() {
    return {
      equipmentTypes: null,
      created: true,
    }
  },

  computed: {
    orderedExtinguisherTypes() {
      if (! this.equipmentTypes) return null
      return this.equipmentTypes.slice().filter(et => et.type == 'Extinguisher').sort((a, b) => moment(a.created_at).isBefore(b.created_at) ? 1 : -1)
    },

    orderedOtherEquipmentTypes() {
      if (! this.equipmentTypes) return null
      return this.equipmentTypes.slice().filter(et => et.type == 'Other').sort((a, b) => moment(a.created_at).isBefore(b.created_at) ? 1 : -1)
    },
  },

  created() {
    this.getUrlCreated()

    this.fetchEquipmentTypes()
  },

  methods: {

    getUrlCreated() {
      this.created = this.$utils.getParamUrl('created');
      this.$utils.setParamUrl('created', null)
      setInterval(() => {
        this.created = false
      }, 1500)
    },

    fetchEquipmentTypes() {
      getEquipmentTypes().then(({ data }) => {
        this.equipmentTypes = data.data
      })
    },

    goToEquipmentType(equipmentType) {
      this.$router.push(`/settings/equipment-types/${equipmentType.id}/edit`)
    },

  },

}
</script>