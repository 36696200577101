<template>
  <div class="card card-condensed" :class="{ 'opacity-25': place.done }">
    <div class="card-header">
      <div class="card-title h5 flex items-center">
        <router-link
          class="flex-1"
          :to="`/clients/${place.client_id}/places/${place.id}`"
          >({{ place.number }}) {{ place.name }}</router-link
        >
        <label class="form-switch">
          <input
            type="checkbox"
            v-model="place.to_do"
            @click="togglePlaceAsDone(place)"
          />
          <i class="form-icon"></i>
        </label>
      </div>
      <div class="card-subtitle pb-2">{{ place.address }}, {{ place.zip }}</div>

      <div v-if="place.email" class="columns my-2">
        <div class="column col-2 text-gray-dark">Email</div>
        <div class="column col-10">{{ place.email }}</div>
      </div>
      <div v-if="place.phone" class="columns my-2">
        <div class="column col-2 text-gray-dark">Telefone</div>
        <div class="column col-10">{{ place.phone }}</div>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-striped table-hover">
        <tr>
          <th>Quantidade</th>
          <th>Tipo</th>
          <th>Estado</th>
        </tr>
        <template
          v-for="(equipmentsByType, type) in groupBy(
            place.equipments.filter((e) => (e.to_do || e.urgent) && e.enabled),
            'equipment_type_id'
          )"
        >
          <tr
            v-for="(equipmentsByStatus, status) in groupBy(
              equipmentsByType,
              'status'
            )"
            :key="`${type}-${status}`"
          >
            <td>{{ equipmentsByStatus.length }}</td>
            <td>{{ equipmentsByStatus[0].equipment_type.full_name }}</td>
            <td>{{ $utils.equipmentStatus(equipmentsByStatus[0]) }}</td>
          </tr>
        </template>
      </table>
    </div>
    <div class="card-footer p-2">
      <label class="form-label text-gray-dark">Observações</label>
      <textarea
        class="form-input"
        :value="place.notes"
        @input="(e) => onChangeNotes(place, e.target.value)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  createUrgentPlace,
  updateUrgentPlace,
  deleteUrgentPlace,
} from "../api";

export default {
  props: {
    place: {
      type: Object,
      required: true,
    },
  },

  methods: {
    groupBy(data, key) {
      return data.slice().reduce((storage, item) => {
        var group = item[key];
        storage[group] = storage[group] || [];
        storage[group].push(item);
        return storage;
      }, {});
    },

    togglePlaceAsDone(place) {
      const action = place.done
        ? createUrgentPlace(place)
        : deleteUrgentPlace(place.id);

      action.then(({ data }) => {
        let updatedPlace = data.data;
        this.$emit("change", updatedPlace);
      });
    },

    onChangeNotes: _.debounce(function(place, notes) {
      updateUrgentPlace(place.id, notes);
    }, 500),
  },
};
</script>
