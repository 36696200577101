<template>
<div class="relative">
  <GmapMap ref="map" :center="center" :zoom="zoom" style="width: 100%; height: 100%;" @zoom_changed="zoomChanged" @bounds_changed="boundsChanged" @center_changed="centerChanged" @click="place = null">
    <GmapMarker v-if="userLocation && userLocationIcon" :icon="userLocationIcon" :position="userLocation" />

    <GmapMarker v-for="place in places" :key="place.id" :icon="icon(place)" :position="place.coordinates" :clickable="true" @click="clickPlace(place)" />

    <GmapInfoWindow v-if="place" :options="options" :position="place.coordinates" :opened="place != null" @closeclick="place = null">
      <slot name="infoWindow" :place="place"></slot>
    </GmapInfoWindow>
  </GmapMap>
  <button v-if="userLocation" @click="setCurrentLocation" type="button" class="gmap-btn">
    <img src="../assets/my-location.png">
  </button>
</div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {

  props: {
    icon: {
      type: Function,
      default: () => {},
    },
    places: {
      type: Array,
      required: true,
    },
    userLocation: {
      type: Object,
      required: true,
    },
    center: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
      default: 17,
    },
  },

  data() {
    return {
      place: null,
      options: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      gmapsLoaded: false,
    };
  },

  computed: {
    google: gmapApi,

    userLocationIcon() {
      if (! this.gmapsLoaded) return null
      return { 
        url: "./assets/user-location.svg",
        size: new this.google.maps.Size(150, 150),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(25, 25),
        scaledSize: new this.google.maps.Size(50, 50),
      }
    },
  },

  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      this.gmapsLoaded = true
    })
  },

  methods: {

    setCurrentLocation() {
      this.$emit('setCenter', this.userLocation)
    },

    boundsChanged() {
      const northEastCorner = this.$refs.map.$mapObject.getBounds().getNorthEast()
      const southWestCorner = this.$refs.map.$mapObject.getBounds().getSouthWest()
      const distance = Math.acos(Math.sin(this.$utils.degreesToRadians(northEastCorner.lat())) * Math.sin(this.$utils.degreesToRadians(southWestCorner.lat())) + Math.cos(this.$utils.degreesToRadians(northEastCorner.lat())) * Math.cos(this.$utils.degreesToRadians(southWestCorner.lat())) * Math.cos(this.$utils.degreesToRadians(southWestCorner.lng()) - this.$utils.degreesToRadians(northEastCorner.lng()))) * 6371
      this.$emit('distanceChanged', distance / 2)
    },

    centerChanged(coordinates) {
      this.$emit('centerChanged', {
        lat: coordinates.lat(),
        lng: coordinates.lng(),
      })
    },

    zoomChanged(zoom) {
      this.$emit('zoomChanged', zoom)
    },

    clickPlace(place) {
      if (this.$scopedSlots.infoWindow) {
        this.place = place
      } else {
        this.$router.push(`/clients/${place.client_id}/places/${place.id}`)
      }
    },

  },

}
</script>