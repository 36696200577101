<template>
<div class="container flex-centered flex-col">
  <NotFound class="max-w-full" :width="400" :height="300" />

  <h2 class="text-xl lg:text-2xl mb-3">404</h2>

  <p class="text-gray-dark text-center mb-1">Tem a certeza que não se enganou na página?</p>
  <p class="text-gray-darker text-center text-xs">A página que procura não existe ou foi movida permanentemente.</p>
</div>
</template>

<script>
import NotFound from '../svg/NotFound'

export default {
  components: {
    NotFound,
  }
}
</script>