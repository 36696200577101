export const calendarConfig = {
    locale: "pt",
    fullDayNames: true,
    fullMonthNames: true,
    languages: { 
        pt: {
            showMore: 'Ver mais',
            dayNameShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
            dayNameLong: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
            monthNameShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
            monthNameLong: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']
        }
    }
}

export const translations = {
    pt: {
        "maintenance.status.created": "Novo",
        "maintenance.status.to_destroy": "Abatido",
        "maintenance.status.to_recharge": "Recarga",
        "maintenance.status.to_maintain": "Manutenção",
        "equipment.status.unmanaged": "Não gerido",
        "equipment.status.destroyed": "Abatido",
        "equipment.status.to_destroy": "Para abater",
        "equipment.status.to_recharge": "Para recarga",
        "equipment.status.to_maintain": "Para manutenção",
        "equipment.status.regularized": "Regularizado",
        "day_of_the_week.mon": "Seg",
        "day_of_the_week.tue": "Ter",
        "day_of_the_week.wed": "Qua",
        "day_of_the_week.thu": "Qui",
        "day_of_the_week.fri": "Sex",
        "day_of_the_week.sat": "Sab",
        "day_of_the_week.sun": "Dom",
    }
}