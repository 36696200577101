<template>
<div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
</div>
<div v-else class="container">
  <div class="columns col-gapless mb-2">
    <div class="column col-6">
      <h2 class="text-xl lg:text-2xl">Manutenções</h2>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-link" to="/maintenances"><i class="icon icon-arrow-left"></i> Voltar</router-link>
    </div>
  </div>

  <ul class="breadcrumb text-xs mb-2">
    <li class="breadcrumb-item">
      <router-link to="/maintenances">Manutenções</router-link>
    </li>
    <li class="breadcrumb-item">
      <router-link :to="`/maintenances/create`">Criar</router-link>
    </li>
  </ul>

  <div class="card">
    <div class="card-body">
        
      <form @submit.prevent="saveMaintenance" class="form-horizontal px-2">
        <div class="form-group mb-2 pb-2">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Local</label>
          </div>
          <div class="w-full lg:w-9/12" style="position: relative">
            <template v-if="! maintenance.place">
              <input type="text" class="form-input input-sm" placeholder="Procurar..." @input="onPlaceSearch" @blur="hidePlaceSearch" @focus="showPlaceSearch = true" v-model="placeSearch">
              <div v-if="placeSearch && showPlaceSearch" style="width: 100%;border: 1px solid #bcc3ce;border-top: 0;max-height: 300px;overflow-y: scroll;position: absolute;z-index: 1;background: #fff;">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr class="c-hand" v-for="place in places" :key="place.id" @click.prevent="selectPlace(place)">
                      <td>{{ place.number }}</td>
                      <td>{{ place.name }}</td>
                    </tr>
                    <tr v-if="placesLoading">
                      <td class="text-center" style="border-bottom: 0;" colspan="99">
                        <div class="loading"></div>
                      </td>
                    </tr>
                    <tr v-if="!placesLoading && places.length === 0">
                      <td class="text-center text-gray-dark" style="border-bottom: 0;" colspan="99">Não foram encontrados locais...</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <span class="chip m-0 mt-2 text-gray-dark">Por escolher</span>
            </template>
            <div v-else class="chip">
              <span class="truncate">({{ maintenance.place.number }}) {{ maintenance.place.name}}</span>
              <button @click.prevent="unselectPlace" type="button" class="btn btn-clear"></button>
            </div>
          </div>
        </div>

        <div class="form-group mb-2 pb-2" :class="{'has-error': errors.serviced_at}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Data do Serviço</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="date" v-model="maintenance.serviced_at">
            <p v-if="errors.serviced_at" class="form-input-hint mb-1">{{ errors.serviced_at[0] }}</p>
          </div>
        </div>

        <div class="form-group mb-2 pb-2">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Items</label>
          </div>
          <div class="w-full lg:w-9/12">
            <table class="table table-striped table-hover">
              <thead>
                <th>Equipamento</th>
                <th>Acção</th>
                <th width="80px">Qtd.</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="form-group">
                      <select v-model="selectedEquipmentTypeId" class="form-select select-sm">
                        <option value="" disabled>Escolher tipo</option>
                        <option v-for="equipmentType in equipmentTypes" :key="equipmentType.id" :value="equipmentType.id">{{ equipmentType.full_name }}</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <select v-model="selectedAction" class="form-select select-sm">
                        <option value="" disabled>Escolher acção</option>
                        <option value="Created" :disabled="! selectedEquipmentType || ! selectedEquipmentType.new_reference">{{ $utils.maintenanceAction("Created") }}</option>
                        <option value="To Destroy" :disabled="! selectedEquipmentType || ! selectedEquipmentType.destroy_reference">{{ $utils.maintenanceAction("To Destroy") }}</option>
                        <option value="To Recharge" :disabled="! selectedEquipmentType || ! selectedEquipmentType.recharge_reference">{{ $utils.maintenanceAction("To Recharge") }}</option>
                        <option value="To Maintain" :disabled="! selectedEquipmentType || ! selectedEquipmentType.maintenance_reference">{{ $utils.maintenanceAction("To Maintain") }}</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="number" class="form-input input-sm text-center" v-model="selectedQuantity">
                    </div>
                  </td>
                  <td class="text-right">
                    <button type="button" @click="addItem" class="btn btn-primary btn-action btn-sm"><i class="icon icon-check"></i></button>
                  </td>
                </tr>
                <tr v-for="(item, index) in maintenance.items" :key="index">
                  <td>{{ item.equipmentType.full_name }}</td>
                  <td>{{ $utils.maintenanceAction(item.action) }}</td>
                  <td><input type="number" class="form-input input-sm text-center" v-model="item.quantity"></td>
                  <td><button @click.prevent="removeItem(index)" type="button" class="btn btn-clear"></button></td>
                </tr>
                <tr v-if="maintenance.items.length === 0">
                  <td class="text-center text-gray-dark" colspan="99">Não há items adicionados</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="form-group">
          <div class="w-full lg:w-3/12"></div>
          <div class="w-full lg:w-9/12">
            <button type="submit" class="btn btn-primary" :class="{'loading': saving, 'disabled': ! canSave}">Criar</button>
          </div>
        </div>

        <portal to="toasts">
          <div v-if="error" class="toast toast-error mb-2">
            <button type="button" @click="error = false" class="btn btn-clear float-right"></button>
            Ocorreu um <strong>erro</strong> a tentar criar a manutenção. (Contacte a equipa de suporte)
          </div>
        </portal>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import { getEquipmentTypes, getPlaces, createMaintenance } from '../../api/'

export default {

  data() {
    return {
      maintenance: {
        place: null,
        place_id: '',
        serviced_at: null,
        items: [],
      },
      selectedEquipmentTypeId: null,
      selectedAction: null,
      selectedQuantity: 1,
      saving: false,
      error: false,
      errors: false,
      showPlaceSearch: false,
      placeSearch: '',
      placesLoading: false,
      places: [],
      equipmentTypes: null,
    }
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },

    canSave() {
      return this.maintenance.place && this.maintenance.items.length > 0
    },

    loading() {
      return !this.equipmentTypes || !this.currentUser
    },

    selectedEquipmentType() {
      if (! this.equipmentTypes) {
        return null
      }
      return this.equipmentTypes.find(type => type.id == this.selectedEquipmentTypeId)
    },

    selectedReference() {
      if (! this.selectedAction) {
        return null
      }

      let reference = null
      if (this.selectedAction == 'Created') {
        reference = this.selectedEquipmentType.new_reference
      }
      if (this.selectedAction == 'To Destroy') {
        reference = this.selectedEquipmentType.destroy_reference
      }
      if (this.selectedAction == 'To Recharge') {
        reference = this.selectedEquipmentType.recharge_reference
      }
      if (this.selectedAction == 'To Maintain') {
        reference = this.selectedEquipmentType.maintenance_reference
      }

      return reference
    },
  },

  created() {
    this.fetchEquipmentTypes()
  },

  methods: {

    resetSelectedEquipmentType() {
      this.selectedEquipmentTypeId = null
      this.selectedAction = null
      this.selectedQuantity = 1
    },

    fetchEquipmentTypes() {
      getEquipmentTypes().then(({ data }) => {
        this.equipmentTypes = data.data
      }).catch(() => {
        this.equipmentTypes = []
      })
    },

    onPlaceSearch: _.throttle(function() {
      this.fetchPlaces()
    }, 500),

    fetchPlaces() {
      if (! this.placeSearch) return

      this.places = []
      this.placesLoading = true
      getPlaces({ search: this.placeSearch }).then(({ data }) => {
        this.places = data.data

        this.placesLoading = false
      })
    },

    hidePlaceSearch() {
      setTimeout(() => {
        this.showPlaceSearch = false
      }, 100)
    },
    
    addItem() {
      if (! this.selectedEquipmentType || ! this.selectedAction) {
        return
      }
      
      this.maintenance.items.push({
        equipmentType: this.selectedEquipmentType,
        action: this.selectedAction,
        reference: this.selectedReference,
        quantity: this.selectedQuantity,
      })

      this.resetSelectedEquipmentType()
    },

    removeItem(index) {
      this.maintenance.items.splice(index, 1)
    },

    selectPlace(place) {
      this.maintenance.place = place
      this.maintenance.place_id = place.id
      this.placeSearch = ''
    },

    unselectPlace() {
      this.maintenance.place = null
      this.maintenance.place_id = null
    },

    saveMaintenance() {
      this.error = false
      this.errors = {}

      createMaintenance(this.maintenance).then(({ data }) => {
        const maintenance = data.data
        this.$router.push(`/maintenances/${maintenance.id}`)
      }).catch(({ response }) => {
        this.error = true
        this.errors = response.data.errors
      })
    }
  }

}
</script>
