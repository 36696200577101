<template>
  <div v-if="!client" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="container">
    <div class="columns col-gapless">
      <div class="column col-6">
        <h2 class="text-xl lg:text-2xl mb-2">Clientes</h2>

        <ul class="breadcrumb text-xs">
          <li class="breadcrumb-item">
            <router-link to="/clients">Clientes</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/clients/${client.id}`">{{
              client.number
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="column col-6 text-right">
        <router-link class="btn btn-link" to="/clients"
          ><i class="icon icon-arrow-left"></i> Voltar</router-link
        >

        <div class="mt-2 text-right">
          <router-link class="btn btn-sm" :to="`/clients/${client.id}/edit`"
            ><i class="icon icon-edit mr-1"></i> Editar</router-link
          >
        </div>
      </div>
    </div>

    <div class="card card-condensed card-table">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th colspan="2" class="my-2">
                {{ client.name }} <small>({{ client.number }})</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="150" class="text-bold">NIF</td>
              <td>{{ client.vat }}</td>
            </tr>
            <tr>
              <td class="text-bold">Endereço</td>
              <td>{{ client.address }}</td>
            </tr>
            <tr>
              <td class="text-bold">Contacto</td>
              <td>{{ client.phone }}</td>
            </tr>
            <tr>
              <td class="text-bold">Email</td>
              <td>{{ client.email }}</td>
            </tr>
            <tr>
              <td class="text-bold">Observações</td>
              <td>{{ client.notes }}</td>
            </tr>
            <tr>
              <td class="text-bold">Locais</td>
              <td>{{ client.places_count }}</td>
            </tr>
            <tr>
              <td class="text-bold">Equipamentos</td>
              <td>{{ client.equipments_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <br />

    <div class="pt-2 mt-2 columns col-gapless">
      <div class="column col-6">
        <h4 class="text-xl">Locais</h4>
      </div>
      <div class="column col-6 text-right">
        <router-link
          class="btn btn-primary"
          :to="`/clients/${client.id}/places/create`"
          ><i class="icon icon-location"></i> Novo Local</router-link
        >
      </div>
    </div>

    <div class="card card-condensed card-table mt-2">
      <div class="card-body">
        <PlacesMap
          v-if="client.places.length > 0"
          :places="client.places"
          style="height: 240px;"
        />
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Nome</th>
              <th class="hidden lg:table-cell">Zona</th>
              <th class="hidden lg:table-cell">Equipamentos</th>
              <th>Próxima Revisão</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="c-hand"
              v-for="place in client.places"
              @click="goToPlace(place)"
              :key="place.id"
            >
              <td>{{ place.number }}</td>
              <td class="hidden lg:table-cell">{{ place.name }}</td>
              <td class="hidden lg:table-cell">{{ place.zone ? place.zone.name : "SEM ZONA" }}</td>
              <td class="lg:hidden">{{ place.name }} <span class="text-xs text-gray-500">({{ place.zone ? place.zone.name : "SEM ZONA" }})</span></td>
              <td class="hidden lg:table-cell">{{ place.equipments_count }}</td>
              <td>
                <template v-if="place.next_maintenance_at">
                  {{ place.next_maintenance_at | dateForHumans }}
                </template>
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <portal to="toasts">
      <div v-if="deleted" class="toast toast-success mb-2">
        <button
          type="button"
          @click="deleted = false"
          class="btn btn-clear float-right"
        ></button>
        O local foi removido com <strong>sucesso</strong>!
      </div>
    </portal>
  </div>
</template>

<script>
import PlacesMap from "../../components/PlacesMap";
import { getClient } from "../../api/";

export default {
  components: {
    PlacesMap,
  },

  data() {
    return {
      client: null,
      deleted: true,
    };
  },

  computed: {
    clientId() {
      return this.$route.params.client;
    },
  },

  created() {
    this.getUrlDeleted();

    this.fetchClient();
  },

  methods: {
    getUrlDeleted() {
      this.deleted = this.$utils.getParamUrl("deleted");
      this.$utils.setParamUrl("deleted", null);
      setInterval(() => {
        this.deleted = false;
      }, 1500);
    },

    goToPlace(place) {
      this.$router.push(`/clients/${this.clientId}/places/${place.id}`);
    },

    fetchClient() {
      getClient(this.clientId)
        .then(({ data }) => {
          this.client = data.data;
        })
        .catch(() => {
          this.$router.push("/404");
        });
    },
  },
};
</script>
