<template>
<div class="container">
  <SettingsNav />

  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="flex-centered">
    <div class="max-w-xs w-full">
      <div class="card">
        <div class="card-header">
          <div class="card-title text-center h2">Moloni</div>
          <div class="card-subtitle text-center">
            <span v-if="! isSetup" class="chip">Desconectado</span>
            <span v-else class="chip bg-success">Conectado</span>
          </div>
        </div>
        <div v-if="isSetup" class="card-body">
          <hr class="my-2 py-2">

          <img v-if="company.image" :src="`https://www.moloni.pt/_imagens/?macro=imgAC_iconeEmpresa_s1&img=${company.image}`">
          
          <h5 class="text-bold text-center">{{ company.name }}</h5>
          
          <h6 class="text-center">({{ company.vat }})</h6>

          <!--<table class="table">
            <tr>
              <td class="text-bold">Customer Number:</td>
              <td>{{ me.user_id }}</td>
            </tr>
            <tr>
              <td class="text-bold">Name:</td>
              <td>{{ me.name }}</td>
            </tr>
            <tr>
              <td class="text-bold">Email:</td>
              <td>{{ me.email }}</td>
            </tr>
            <tr>
              <td class="text-bold">Phone:</td>
              <td>{{ me.cellphone }}</td>
            </tr>
          </table>-->

        </div>
        <div v-if="! isSetup" class="card-footer flex-centered">
          <a :href="moloniUrl" class="btn btn-primary">Autorizar integração</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getMoloni } from '../../api'
import SettingsNav from '../../components/SettingsNav'

export default {

  components: {
    SettingsNav,
  },

  data() {
    return {
      loading: true,
      isSetup: false,
      moloniUrl: null,
      me: null,
      company: null,
    }
  },

  created() {
    this.fetchMoloni()  
  },

  methods: {

    fetchMoloni() {
      this.loading = true
      
      getMoloni().then(response => {
        this.isSetup = response.data.is_setup
        this.moloniUrl = response.data.moloni_url
        this.me = response.data.me
        this.company = response.data.company
      }).finally(() => {
        this.loading = false
      })
    },

  }

}
</script>