<template>
    <div class="relative">
        <GmapMap ref="map" :center="center" :zoom="zoom" style="width: 100%; height: 100%;">
            <GmapMarker v-if="place" :position="place" :draggable="true" @dragend="dragend"></GmapMarker>
        </GmapMap>
        <button v-if="userLocation" @click="setCurrentLocation" type="button" class="gmap-btn">
            <img src="../assets/my-location.png">
        </button>
    </div>
</template>

<script>
export default {

    props: {
        coordinates: {
            type: Object,
        },
    },

    data() {
        return {
            zoom: 5,
            userLocation: null,
            center: {
                lat: 39.433778,
                lng: -8.207259,
            },
            place: {
                lat: 39.433778,
                lng: -8.207259,
            },
        }
    },

    watch: {

        coordinates(coordinates) {
            if (coordinates && coordinates.lat && coordinates.lng) {
                this.place = coordinates
            }
        }

    },

    created() {
        if (this.coordinates && this.coordinates.lat && this.coordinates.lng) {
            this.place = this.coordinates
            this.fitToMarker(this.coordinates)
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            })
        }
    },

    methods: {

        dragend(event) {
            this.$emit('change', {
                coordinates: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                }
            })
        },

        fitToMarker(coordinates) {
            if (coordinates.lat && coordinates.lng) {
                this.center = coordinates
                this.zoom = 14
            }
        },

        setCurrentLocation() {
            this.$emit('change', {
                coordinates: this.userLocation,
            })
            this.fitToMarker(this.userLocation)
        },
        
    }

}
</script>
