<template>
  <div class="container">
    <div class="columns col-gapless">
      <div class="column col-6">
        <h2 class="text-2xl mb-2">Listagem</h2>
      </div>
      <div class="column col-6 text-right">
        <router-link class="btn mb-2 pb-2" :to="`/nearby`">
          <i class="icon icon-location"></i> Mapa
        </router-link>
      </div>
    </div>

    <ul class="tab tab-block lists-nav">
      <li
        class="tab-item"
        v-for="month in 12"
        :key="month"
        :class="{ active: selectedMonth === month }"
      >
        <a @click.prevent="selectMonth(month)" href="javascript:;">{{
          $utils.monthShortName(month)
        }}</a>
      </li>
    </ul>

    <div v-if="!placesByZone" class="hero">
      <div class="loading loading-lg"></div>
    </div>
    <div v-else>
      <div class="mb-4 columns col-gapless">
        <div class="w-full lg:w-auto lg:mr-4 mb-2">
          <label class="form-label">Zonas:</label>
          <select
            class="form-select"
            type="text"
            multiple
            v-model="selectedZones"
          >
            <option value="all">Todos</option>
            <option
              v-for="zone in zones"
              :key="zone ? zone.id : -1"
              :value="zone ? zone.id : null"
              >{{ zone ? zone.name : "SEM ZONA" }}</option
            >
          </select>
        </div>
        <div class="w-full lg:w-auto lg:mr-4">
          <label class="form-label">Equipamentos:</label>
          <select
            class="form-select"
            type="text"
            multiple
            v-model="selectedEquipmentTypes"
          >
            <option value="all">Todos</option>
            <option
              v-for="equipmentType in equipmentTypes"
              :key="equipmentType.id"
              :value="equipmentType.id"
              >{{ equipmentType.full_name }}</option
            >
          </select>
        </div>
        <div class="flex-1 text-right mt-6 lg:mt-0">
          <a class="btn btn-sm" target="_blank" :href="downloadUrl">
            <i class="icon icon-download mr-1"></i> Download
          </a>
        </div>
      </div>

      <hr class="py-2" />

      <div
        class="mb-2"
        v-for="places in filteredPlacesByZone"
        :key="places[0].zone ? places[0].zone.id : -1"
      >
        <h3 class="text-lg text-bold mb-2">
          {{ places[0].zone ? places[0].zone.name : "SEM ZONA" }}
        </h3>

        <UrgentPlaceCard
          class="card-table mb-4"
          v-for="place in places"
          :key="place.id"
          :place="place"
          @change="updatePlace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UrgentPlaceCard from "../components/UrgentPlaceCard";
import { getUrgentPlaces, getEquipmentTypes } from "../api";

export default {
  components: {
    UrgentPlaceCard,
  },

  data() {
    return {
      selectedMonth: new Date().getMonth() + 1,
      download: null,
      placesByZone: null,
      equipmentTypes: null,
      selectedZones: [],
      selectedEquipmentTypes: [],
      savedScroll: 0,
    };
  },

  created() {
    const selectedZones = JSON.parse(localStorage.getItem("selectedZones"));
    if (selectedZones) {
      this.selectedZones = selectedZones;
    }

    const selectedMonth = JSON.parse(localStorage.getItem("selectedMonth"));
    if (selectedMonth) {
      this.selectedMonth = selectedMonth;
    }

    const selectedEquipmentTypes = JSON.parse(
      localStorage.getItem("selectedEquipmentTypes")
    );
    if (selectedEquipmentTypes) {
      this.selectedEquipmentTypes = selectedEquipmentTypes;
    }

    this.fetchEquipmentTypes();
    this.fetchUrgentPlacesByZone();
  },

  watch: {
    selectedZones(selectedZones) {
      if (selectedZones.includes("all")) {
        this.selectedZones = [];
        localStorage.removeItem("selectedZones");
      } else {
        localStorage.setItem("selectedZones", JSON.stringify(selectedZones));
      }
    },
    selectedEquipmentTypes(selectedEquipmentTypes) {
      if (selectedEquipmentTypes.includes("all")) {
        this.selectedEquipmentTypes = [];
        localStorage.removeItem("selectedEquipmentTypes");
      } else {
        localStorage.setItem(
          "selectedEquipmentTypes",
          JSON.stringify(selectedEquipmentTypes)
        );
      }
    },
    selectedMonth(selectedMonth) {
      localStorage.setItem("selectedMonth", JSON.stringify(selectedMonth));
    },
    filteredPlacesByZone(filteredPlacesByZone) {
      if (filteredPlacesByZone.length) {
        this.perserveScroll();
      }
    },
    savedScroll(savedScroll) {
      this.$nextTick(() => {
        window.scrollTo(0, savedScroll);
      });
    },
  },

  computed: {
    zones() {
      if (!this.placesByZone) return [];
      return this.placesByZone
        .map((places) => places[0].zone)
        .sort((a, b) => {
          if (!a) return -1;
          if (!b) return 1;
          return a.name.localeCompare(b.name);
        });
    },

    filteredPlacesByZone() {
      if (!this.placesByZone) return [];
      let placesByZone = this.placesByZone
        .slice()
        .map((places) => {
          return places.filter((place) => {
            if (this.selectedEquipmentTypes.length <= 0) return true;
            return !!place.equipments
              .filter((e) => (e.to_do || e.urgent) && e.enabled)
              .find((e) =>
                this.selectedEquipmentTypes.includes(e.equipment_type_id)
              );
          });
        })
        .filter((places) => places.length > 0);

      if (this.selectedZones.length > 0) {
        placesByZone = placesByZone.filter((places) =>
          this.selectedZones.includes(places[0].zone_id)
        );
      }

      return placesByZone;
    },

    downloadUrl() {
      if (this.selectedZones === null) return this.download;
      return `${this.download}&zones=${this.selectedZones}`;
    },
  },

  methods: {
    perserveScroll() {
      const savedScroll = JSON.parse(localStorage.getItem("savedScroll"));
      this.savedScroll = savedScroll ? savedScroll.y : 0;
    },

    selectMonth(month) {
      this.selectedMonth = month;
      this.selectedZones = [];
      this.fetchUrgentPlacesByZone();
    },

    fetchUrgentPlacesByZone() {
      this.placesByZone = null;

      getUrgentPlaces({ month: this.selectedMonth }).then(({ data }) => {
        this.placesByZone = data.data;
        this.download = data.download;
      });
    },

    fetchEquipmentTypes() {
      getEquipmentTypes().then(({ data }) => {
        this.equipmentTypes = data.data;
      });
    },

    updatePlace(updatedPlace) {
      this.placesByZone.map((places) => {
        return places.map((place) => {
          if (place.id === updatedPlace.id) {
            return Object.assign(place, {
              notes: updatedPlace.notes,
              done: updatedPlace.done,
              to_do: updatedPlace.to_do,
              updated_at: updatedPlace.updated_at,
            });
          }
          return place;
        });
      });
    },
  },
};
</script>
