import { api } from "./index.js";

export const getZones = () => {
  return api("get", "/api/zones");
};

export const createZone = (zone) => {
  return api("post", "/api/zones", zone);
};

export const getZone = (id) => {
  return api("get", `/api/zones/${id}`);
};

export const updateZone = (id, zone) => {
  return api("put", `/api/zones/${id}`, zone);
};

export const deleteZone = (id) => {
  return api("delete", `/api/zones/${id}`);
};

export const mergeZones = (ids, name) => {
  return api("post", "/api/merged-zones", { ids, name });
};
