<template>
<div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
</div>
<div v-else class="container">
  <h2 class="text-2xl mb-2">Perfil</h2>

  <div class="card">
    <div class="card-body">
      <form @submit.prevent="saveProfile" class="form-horizontal px-2">
        <div class="form-group" :class="{'has-error': errors.name}">
          <div class="col-3">
            <label class="form-label">Nome</label>
          </div>
          <div class="col-9">
            <input class="form-input" type="text" v-model="user.name">
            <p v-if="errors.name" class="form-input-hint mb-1">{{ errors.name[0] }}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="col-3">
            <label class="form-label">Email</label>
          </div>
          <div class="col-9">
            <input class="form-input" type="email" pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" disabled v-model="user.email">
          </div>
        </div>

        <!-- <div class="form-group" :class="{'has-error': errors.prefix}">
          <div class="col-3">
            <label class="form-label">Sigla</label>
          </div>
          <div class="col-9">
            <input class="form-input" type="text" v-model="user.prefix">
            <p v-if="errors.prefix" class="form-input-hint mb-1">{{ errors.prefix[0] }}</p>
          </div>
        </div> -->

        <!-- <div class="form-group" :class="{'has-error': errors.vehicle_id}">
          <div class="col-3">
            <label class="form-label">Viatura</label>
          </div>
          <div class="col-9">
            <select class="form-select" type="text" v-model="user.vehicle_id">
              <option :value="null">Escolher viatura</option>
              <option v-for="vehicle in vehicles" :value="vehicle.id" :key="vehicle.id">{{ `${vehicle.plate_number} - ${vehicle.brand} ${vehicle.model}` }}</option>
            </select>
            <p v-if="errors.vehicle_id" class="form-input-hint mb-1">{{ errors.vehicle_id[0] }}</p>
          </div>
        </div> -->

        <div class="form-group">
          <div class="col-3"></div>
          <div class="col-9">
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </div>

        <portal to="toasts">
          <div v-if="success" class="toast toast-success mb-2">
            <button type="button" @click="success = false" class="btn btn-clear float-right"></button>
            O perfil foi alterado com <strong>sucesso</strong>!
          </div>
        </portal>

        <portal to="toasts">
          <div v-if="error" class="toast toast-error mb-2">
            <button type="button" @click="error = false" class="btn btn-clear float-right"></button>
            Ocorreu um <strong>erro</strong> a tentar guardar o perfil.
          </div>
        </portal>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { getVehicles } from '../api/'

export default {

  data() {
    return {
      user: null,
      success: false,
      error: false,
      errors: {},
      vehicles: null,
    }
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    loading() {
      return !this.user || !this.vehicles
    },
  },

  watch: {
    currentUser(user) {
      if (user) {
        this.user = Object.assign({}, user)
      }
    },
  },

  created() {
    if (this.currentUser) {
      this.user = Object.assign({}, this.currentUser)
    }
    this.fetchVehicles()
  },

  methods: {

    fetchVehicles() {
      getVehicles().then(({ data }) => {
        this.vehicles = data.data
      })
    },

    saveProfile() {
      this.error = false
      this.errors = {}

      this.$store.dispatch('updateProfile', this.user).then(user => {
        this.user = Object.assign({}, user)
        this.success = true
        setTimeout(() => {
          this.success = false
        }, 2000)
      }).catch(({ response }) => {
        this.error = true
        this.errors = response.data.errors
      })
    },

  },

}
</script>

