import moment from "moment";
import { calendarConfig, translations } from "./config";

export const getParamUrl = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const setParamUrl = (param, value) => {
  const params = new URLSearchParams(window.location.search);
  if (value !== null && value !== undefined && value !== "")
    params.set(param, value);
  else params.delete(param);
  window.history.pushState(
    {},
    "SoftExtint",
    [window.location.pathname, params.toString()].filter(Boolean).join("?")
  );
};

export const goBack = (router, url) => {
  if (window.history.length > 2) {
    window.history.back();
  } else {
    router.push(url);
  }
};

export const validDate = (date, format) => {
  return moment(date, format, true).isValid();
};

export const equipmentStatus = (equipment) => {
  if (!equipment.enabled) {
    return translate("equipment.status.unmanaged", "pt");
  }

  if (equipment.status === "Destroyed") {
    return translate("equipment.status.destroyed", "pt");
  }

  if (equipment.status === "To Destroy") {
    return translate("equipment.status.to_destroy", "pt");
  }

  if (equipment.status === "To Recharge") {
    return translate("equipment.status.to_recharge", "pt");
  }

  if (equipment.status === "To Maintain") {
    return translate("equipment.status.to_maintain", "pt");
  }

  if (equipment.status === "Regularized") {
    return translate("equipment.status.regularized", "pt");
  }

  return translate("equipment.status.regularized", "pt");
};

export const maintenanceAction = (status) => {
  if (status === "Created") {
    return translate("maintenance.status.created", "pt");
  }

  if (status === "To Destroy") {
    return translate("maintenance.status.to_destroy", "pt");
  }

  if (status === "To Recharge") {
    return translate("maintenance.status.to_recharge", "pt");
  }

  if (status === "To Maintain") {
    return translate("maintenance.status.to_maintain", "pt");
  }

  return translate("maintenance.status.to_maintain", "pt");
};

export const maintenanceActions = [
  "Created",
  "To Destroy",
  "To Recharge",
  "To Maintain",
];

export const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

export const dayOfTheWeek = (day) => {
  return translate("day_of_the_week." + day, "pt");
};

export const monthShortName = (month) => {
  return calendarConfig.languages["pt"].monthNameShort[month - 1];
};

export const translate = (key, lang) => {
  return translations[lang][key];
};

export const trim = (str, chr) => {
  var rgxtrim = !chr
    ? new RegExp("^\\s+|\\s+$", "g")
    : new RegExp("^" + chr + "+|" + chr + "+$", "g");
  return str.replace(rgxtrim, "");
};

export const rtrim = (str, chr) => {
  var rgxtrim = !chr ? new RegExp("\\s+$") : new RegExp(chr + "+$");
  return str.replace(rgxtrim, "");
};

export const ltrim = (str, chr) => {
  var rgxtrim = !chr ? new RegExp("^\\s+") : new RegExp("^" + chr + "+");
  return str.replace(rgxtrim, "");
};
