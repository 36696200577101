import { api } from './index.js'

export const getClients = (params) => {
    return api('get', '/api/clients', null, params);
}

export const createClient = (client) => {
    return api('post', '/api/clients', client);
}

export const getClient = (id) => {
    return api('get', `/api/clients/${id}`);
}

export const updateClient = (id, client) => {
    return api('put', `/api/clients/${id}`, client);
}

export const deleteClient = (id) => {
    return api('delete', `/api/clients/${id}`);
}