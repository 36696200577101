<template>
<div class="container">
  <SettingsNav />

  <div class="columns col-gapless">
    <div class="column col-8">
      <h2 class="text-xl lg:text-2xl mb-2">Tipos de Equipamentos</h2>
    </div>
    <div class="column col-4 text-right">
      <router-link class="btn btn-link mb-2 pb-2" to="/settings/equipment-types"><i class="icon icon-arrow-left"></i> Voltar</router-link>
    </div>
  </div>

  <ul class="breadcrumb text-xs mb-2">
    <li class="breadcrumb-item">
      <router-link to="/settings/equipment-types">Tipos de Equipamentos</router-link>
    </li>
    <li class="breadcrumb-item">
      <router-link :to="`/settings/equipment-types/create`">Criar</router-link>
    </li>
  </ul>

  <div class="card">
    <div class="card-body">
      <form @submit.prevent="saveEquipmentType" class="form-horizontal px-2">
        <div class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.name}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Nome</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.name">
            <p v-if="errors.name" class="form-input-hint mb-1">{{ errors.name[0] }}</p>
          </div>
        </div>

        <div class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.type}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Tipo</label>
          </div>
          <div class="w-full lg:w-9/12">
            <select v-model="equipmentType.type" class="form-select">
              <option value="Extinguisher">Extintor</option>
              <option value="Other">Outro</option>
            </select>
            <p v-if="errors.type" class="form-input-hint mb-1">{{ errors.type[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.description}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Descrição</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.description">
            <p v-if="errors.description" class="form-input-hint mb-1">{{ errors.description[0] }}</p>
          </div>
        </div>

        <div class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.maintenance_period}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Período de Manutenção <span class="text-xs">(Meses)</span></label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.maintenance_period">
            <p v-if="errors.maintenance_period" class="form-input-hint mb-1">{{ errors.maintenance_period[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.recharge_period}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Período de Recarga <span class="text-xs">(Meses)</span></label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.recharge_period">
            <p v-if="errors.recharge_period" class="form-input-hint mb-1">{{ errors.recharge_period[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.life_period}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Tempo de Vida <span class="text-xs">(Meses)</span></label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.life_period">
            <p v-if="errors.life_period" class="form-input-hint mb-1">{{ errors.life_period[0] }}</p>
          </div>
        </div>

        <div class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.maintenance_reference}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Referência Manutenção</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.maintenance_reference">
            <p v-if="errors.maintenance_reference" class="form-input-hint mb-1">{{ errors.maintenance_reference[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.recharge_reference}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Referência Recarga</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.recharge_reference">
            <p v-if="errors.recharge_reference" class="form-input-hint mb-1">{{ errors.recharge_reference[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.destroy_reference}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Referência Abatido</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.destroy_reference">
            <p v-if="errors.destroy_reference" class="form-input-hint mb-1">{{ errors.destroy_reference[0] }}</p>
          </div>
        </div>

        <div v-if="isExtinguisher" class="form-group flex-col mb-3 lg:flex-row lg:mb-0" :class="{'has-error': errors.new_reference}">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Referência Novo</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="equipmentType.new_reference">
            <p v-if="errors.new_reference" class="form-input-hint mb-1">{{ errors.new_reference[0] }}</p>
          </div>
        </div>

        <div class="form-group flex-col mb-3 lg:flex-row lg:mb-0">
          <div class="w-full lg:w-3/12"></div>
          <div class="w-full lg:w-9/12">
            <button type="submit" class="btn btn-primary">Criar</button>
          </div>
        </div>

        <portal to="toasts">
          <div v-if="error" class="toast toast-error mb-2">
            <button type="button" @click="error = false" class="btn btn-clear float-right"></button>
            Ocorreu um <strong>erro</strong> a tentar criar o tipo de equipamento.
          </div>
        </portal>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { createEquipmentType } from '../../api/'
import SettingsNav from '../../components/SettingsNav'

export default {

  components: {
    SettingsNav,
  },

  data() {
    return {
      equipmentType: {
        name: '',
        type: 'Extinguisher',
        description: '',
        maintenance_period: '',
        recharge_period: '',
        life_period: '',
        maintenance_reference: '',
        recharge_reference: '',
        destroy_reference: '',
        new_reference: '',
      },
      error: false,
      errors: {},
    }
  },

  computed: {
    isExtinguisher() {
      return this.equipmentType.type == 'Extinguisher';
    },
  },

  methods: {
    saveEquipmentType() {
      this.error = false
      this.errors = {}

      createEquipmentType(this.equipmentType).then(() => {
        this.$router.push(`/settings/equipment-types?created=true`)
      }).catch(({ response }) => {
        this.error = true
        this.errors = response.data.errors
      })
    },
  },

}
</script>