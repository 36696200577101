import { api } from './index.js'

export const getVehicles = () => {
    return api('get', '/api/vehicles');
}

export const createVehicle = (vehicle) => {
    return api('post', '/api/vehicles', vehicle);
}

export const getVehicle = (id) => {
    return api('get', `/api/vehicles/${id}`);
}

export const updateVehicle = (id, vehicle) => {
    return api('put', `/api/vehicles/${id}`, vehicle);
}