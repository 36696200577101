<template>
<div class="container">
  <div class="columns col-gapless mb-3">
    <div class="column col-6">
      <h2 class="text-xl lg:text-2xl">Manutenções</h2>
    </div>
    <div class="column col-6 text-right">
      <router-link class="btn btn-primary" to="/maintenances/create"><i class="icon icon-plus"></i> Nova Manutenção</router-link>
    </div>
  </div>

  <div class="mb-2 flex items-center" v-if="! loading">
    <button @click="importMaintenances" class="btn btn-sm text-center" :class="{'disabled': importDisabled}" style="min-width: 95px;">
      <template v-if="! importing">
        <i class="icon icon-refresh mr-1"></i> Importar
      </template>
      <div v-else class="loading"></div>
    </button>
    <p class="text-gray-dark text-small ml-2" v-if="lastFetchedAt">Última actualização a <strong>{{ lastFetchedAt | datetime }}</strong>.</p>
  </div>

  <div v-if="loading" class="hero">
    <div class="loading loading-lg"></div>
  </div>
  <div v-else class="card card-condensed card-table">
    <div class="card-body">
      <table class="table table-striped table-hover text-xs lg:text-sm">
        <thead>
          <tr>
            <th class="hidden lg:table-cell">No.</th>
            <th>Local</th>
            <th>Equipamentos</th>
            <!-- <th>Informação Externa</th> -->
            <th>Data do Serviço</th>
            <!-- <th>Data de Criação</th> -->
          </tr>
        </thead>
        <tbody>
          <tr class="c-hand" v-for="maintenance in maintenances" @click="goToMaintenance(maintenance)" :key="maintenance.id">
            <td class="hidden lg:table-cell">{{ maintenance.number }}</td>
            <td>({{ maintenance.place.number }}) {{ maintenance.place.name }}</td>
            <td>{{ maintenance.equipments.length }}</td>
            <!-- <td>
              <template v-if="maintenance.external_id">{{ maintenance.external_id }} ({{ maintenance.external_service }})</template>
              <template v-else>-</template>
            </td> -->
            <td>{{ maintenance.serviced_at | date }}</td>
            <!-- <td>{{ maintenance.created_at | date }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="lastPage !== 1" class="card-footer">
      <Pagination :page="currentPage" :last-page="lastPage" @change="changePage" />
    </div>
  </div>

  <portal to="toasts">
    <div v-if="deleted" class="toast toast-success mb-2">
      <button type="button" @click="deleted = false" class="btn btn-clear float-right"></button>
      A manutenção foi revertida com <strong>sucesso</strong>!
    </div>
  </portal>
</div>
</template>
    
<script>
import moment from 'moment'
import { getMaintenances, importMaintenances } from '../../api/'
import Pagination from '../../components/Pagination'

export default {

  components: {
    Pagination
  },

  data() {
    return {
      loading: true,
      maintenances: [],
      lastFetchedAt: null,
      importing: false,
      currentPage: 1,
      lastPage: null,
      deleted: true,
    }
  },

  created() {
    this.getUrlDeleted()
    this.currentPage = this.getUrlPage()
    this.onUrlChange()

    window.addEventListener('popstate', this.onUrlChange)
    this.$on('beforeDestroy', () => {
      window.removeEventListener('popstate', this.onUrlChange)
    })
  },

  computed: {
    importDisabled() {
      if (this.lastFetchedAt && moment().isSameOrBefore(moment(this.lastFetchedAt), 'day')) {
        return true
      }
      return this.importing
    },
  },

  methods: {

    getUrlDeleted() {
      this.deleted = this.$utils.getParamUrl('deleted')
      this.$utils.setParamUrl('deleted', null)
      setInterval(() => {
        this.deleted = false
      }, 1500)
    },

    onUrlChange() {
      this.fetchMaintenances()
    },

    getUrlPage() {
      let page = parseInt(this.$utils.getParamUrl('page'))
      if (! isNaN(page)) {
        return page
      }
      return 1;
    },

    changePage(page) {
      this.$utils.setParamUrl('page', page)
      this.currentPage = page
      this.fetchMaintenances()
    },

    fetchMaintenances() {
      this.loading = true

      getMaintenances({ page: this.currentPage }).then(({ data }) => {
        this.maintenances = data.data
        this.lastFetchedAt = data.meta.last_fetched_at
        this.importing = data.meta.importing
        this.currentPage = data.meta.current_page
        this.lastPage = data.meta.last_page

        this.loading = false
      })
    },

    importMaintenances() {
      this.importing = true
      importMaintenances().then(({ data }) => {
        this.lastFetchedAt = data.last_fetched_at
        this.importing = data.importing
      })
    },

    goToMaintenance(maintenance) {
      this.$router.push(`/maintenances/${maintenance.id}`)
    },

  },

}
</script>