import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export const api = (method, url, data, params) => {
  const request = axios({
    method,
    url,
    data,
    params,
  });

  request.catch((error) => {
    // eslint-disable-next-line
    console.error("Api error:", error);
  });

  return request;
};

export const login = (email, password) => {
  return api("post", "/login", { email, password });
};

export const logout = () => {
  return api("post", "/logout");
};

export const me = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  return api("get", "/api/me");
};

export const updateProfile = (profile) => {
  return api("put", "/api/me", profile);
};

export * from "./clients.js";
export * from "./places.js";
export * from "./equipments.js";
export * from "./maintenances.js";
export * from "./carriages.js";
export * from "./vehicles.js";
export * from "./zones.js";
export * from "./services.js";
