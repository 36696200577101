import { api } from './index.js'

export const getMaintenances = (params) => {
    return api('get', '/api/maintenances', null, params);
}

export const createMaintenance = (maintenance) => {
    return api('post', '/api/maintenances', maintenance);
}

export const getMaintenance = (id) => {
    return api('get', `/api/maintenances/${id}`);
}

export const deleteMaintenance = (id) => {
    return api('delete', `/api/maintenances/${id}`);
}

export const importMaintenances = () => {
    return api('get', '/api/imported-maintenances', null);
}