<template>
  <div class="container">
    <SettingsNav />

    <div v-if="!orderedZones" class="hero">
      <div class="loading loading-lg"></div>
    </div>
    <template v-else>
      <div class="columns col-gapless mb-3">
        <div class="column col-8">
          <h2 class="text-xl lg:text-2xl">
            Zonas <small>({{ this.orderedZones.length }})</small>
          </h2>
        </div>
        <div class="column col-4 text-right">
          <router-link class="btn btn-primary" to="/settings/zones/create">
            <i class="icon icon-photo"></i> Nova Zona
          </router-link>
        </div>
      </div>

      <div class="columns col-gapless mb-2">
        <div class="column col-9">
          
          <div class="flex">
            <div class="w-3/12 lg:w-2/12 flex items-center">
              <label>Procurar:</label>
            </div>
            <div class="w-9/12 lg:w-5/12 ">
              <input type="text" class="form-input" v-model="search" />
            </div>
          </div>

        </div>
        <div class="column col-3 text-right">
          <button
            @click="isMergeModalOpen = true"
            class="btn btn-default"
            :class="{ disabled: checkedZones.length < 2 }"
          >
            Fundir
          </button>
        </div>
      </div>

      <div class="card card-condensed card-table">
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>
                  <label
                    class="form-checkbox"
                    @click.prevent="toggleCheckedZones"
                  >
                    <input ref="checkboxAllZones" type="checkbox" />
                    <i class="form-icon"></i>
                  </label>
                </th>
                <th>Nome</th>
                <th>Locais</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="zone in orderedZones" :key="zone.id">
                <td>
                  <label class="form-checkbox">
                    <input type="checkbox" :value="zone" v-model="checkedZones" />
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td class="text-xs">
                  <p class="truncate w-24">{{ zone.name }}</p>
                </td>
                <td>{{ zone.places_count }}</td>
                <td class="text-right">
                  <button
                    @click="goToZone(zone)"
                    type="button"
                    class="btn btn-sm"
                  >
                    <i class="icon icon-edit mr-1"></i>
                    <span class="hidden lg:inline-flex">Editar</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <Modal
      :is-open="isMergeModalOpen"
      @close="
        if (!merging) {
          isMergeModalOpen = false;
        }
      "
      @confirm="mergeZones"
    >
      <template slot="title"
        >Atenção!</template
      >
      <template>
        <div class="flex-centered mb-2 pb-2 text-danger text-error">
          <i class="icon icon-4x icon-copy"></i>
        </div>
        <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
        <p class="mb-2 text-gray">
          Todos os locais vão passar a pertencer a esta nova zona.
        </p>

        <p class="mb-0 text-gray-darker">
          Está prestes a fundir as seguintes zonas:
        </p>
        <ul class="mt-0 mb-4">
          <li v-for="zone in checkedZones" :key="zone.id">
            {{ zone.name }} ({{ zone.places_count }})
          </li>
        </ul>

        <div class="form-group" :class="{ 'has-error': errors.name }">
          <div class="w-full lg:w-3/12">
            <label class="form-label">Novo nome:</label>
          </div>
          <div class="w-full lg:w-9/12">
            <input class="form-input" type="text" v-model="newZone" />
            <p v-if="errors.name" class="form-input-hint mb-1">
              {{ errors.name[0] }}
            </p>
          </div>
        </div>
      </template>
      <template slot="actions" slot-scope="{ close, confirm }">
        <button
          type="button"
          class="btn mr-2"
          :class="{ disabled: merging }"
          @click="close"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-error"
          :class="{ disabled: checkedZones.length < 2 || !newZone || merging }"
          @click="confirm"
        >
          <span v-if="!merging">Confirmar</span>
          <span v-else>A fundir...</span>
        </button>
      </template>
    </Modal>

    <portal to="toasts">
      <div v-if="created" class="toast toast-success mb-2">
        <button
          type="button"
          @click="created = false"
          class="btn btn-clear float-right"
        ></button>
        A zona foi criada com <strong>sucesso</strong>!
      </div>

      <div v-if="deleted" class="toast toast-success mb-2">
        <button
          type="button"
          @click="deleted = false"
          class="btn btn-clear float-right"
        ></button>
        A zona foi removida com <strong>sucesso</strong>!
      </div>

      <div v-if="success" class="toast toast-success mb-2">
        <button
          type="button"
          @click="success = false"
          class="btn btn-clear float-right"
        ></button>
        As zonas foram fundidas com <strong>sucesso</strong>!
      </div>

      <div v-if="error" class="toast toast-error mb-2">
        <button
          type="button"
          @click="error = false"
          class="btn btn-clear float-right"
        ></button>
        Ocorreu um <strong>erro</strong> a tentar fundir as zonas.
      </div>
    </portal>
  </div>
</template>

<script>
import { getZones, mergeZones } from "../../api/";
import Modal from "../../components/Modal";
import SettingsNav from "../../components/SettingsNav";

export default {
  components: {
    SettingsNav,
    Modal,
  },

  data() {
    return {
      zones: null,
      created: true,
      checkedZones: [],
      deleted: true,
      search: "",
      isMergeModalOpen: false,
      newZone: "",
      success: false,
      error: false,
      errors: {},
      merging: false,
    };
  },

  computed: {
    orderedZones() {
      if (!this.zones) return null;
      let zones = this.zones
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      if (this.search) {
        zones = zones.filter((zone) =>
          zone.name.includes(this.search.toUpperCase())
        );
      }
      return zones;
    },
  },

  watch: {
    search() {
      this.checkedZones = [];
    },

    checkedZones(checkedZones) {
      this.$refs.checkboxAllZones.indeterminate = false;
      if (this.orderedZones && checkedZones.length == this.orderedZones.length) {
        this.$refs.checkboxAllZones.checked = true;
      } else {
        this.$refs.checkboxAllZones.checked = false;
        if (checkedZones.length > 0) {
          this.$refs.checkboxAllZones.indeterminate = true;
        }
      }
    },
  },

  created() {
    this.getUrlDeleted();
    this.getUrlCreated();

    this.fetchZones();
  },

  methods: {
    getUrlDeleted() {
      this.deleted = this.$utils.getParamUrl("deleted");
      this.$utils.setParamUrl("deleted", null);
      setInterval(() => {
        this.deleted = false;
      }, 1500);
    },

    getUrlCreated() {
      this.created = this.$utils.getParamUrl("created");
      this.$utils.setParamUrl("created", null);
      setInterval(() => {
        this.created = false;
      }, 1500);
    },

    fetchZones() {
      getZones().then(({ data }) => {
        this.zones = data.data;
      });
    },

    goToZone(zone) {
      this.$router.push(`/settings/zones/${zone.id}/edit`);
    },

    toggleCheckedZones() {
      if (this.checkedZones.length > 0) {
        this.checkedZones = [];
      } else {
        this.checkedZones = this.orderedZones.slice();
      }
    },

    mergeZones() {
      this.merging = true;
      this.error = false;
      this.errors = {};

      mergeZones(this.checkedZones.map((zone) => zone.id), this.newZone)
        .then(({ data }) => {
          this.zones = data.data;
          this.newZone = "";
          this.checkedZones = [];
          this.isMergeModalOpen = false;
          this.merging = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
        })
        .catch(({ response }) => {
          this.merging = false;
          this.error = true;
          this.errors = response.data.errors;
        });
    },
  },
};
</script>
