<template>
  <div class="container">
    <SettingsNav />

    <div v-if="!zone" class="hero">
      <div class="loading loading-lg"></div>
    </div>
    <template v-else>
      <div class="columns col-gapless mb-2">
        <div class="column col-6">
          <h2 class="text-xl lg:text-2xl">Zonas</h2>
        </div>
        <div class="column col-6 text-right">
          <router-link class="btn btn-link" to="/settings/zones">
            <i class="icon icon-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>

      <div class="columns col-gapless mb-2">
        <div class="column col-6">
          <ul class="breadcrumb text-xs">
            <li class="breadcrumb-item">
              <router-link to="/settings/zones">Zonas</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="`/settings/zones/${zone.id}/edit`">{{
                zone.name
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="column col-6 flex items-center justify-end">
          <button
            @click="isDeleteModalOpen = true"
            class="btn btn-sm btn-error text-normal"
          >
            <i class="icon icon-delete mr-1"></i> Eliminar
          </button>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="saveZone" class="form-horizontal px-2">
            <div class="form-group" :class="{ 'has-error': errors.name }">
              <div class="col-3">
                <label class="form-label">Nome</label>
              </div>
              <div class="col-9">
                <input class="form-input" type="text" v-model="editZone.name" />
                <p v-if="errors.name" class="form-input-hint mb-1">
                  {{ errors.name[0] }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="col-3"></div>
              <div class="col-9">
                <button type="submit" class="btn btn-primary">Guardar</button>
              </div>
            </div>

            <portal to="toasts">
              <div v-if="success" class="toast toast-success mb-2">
                <button
                  type="button"
                  @click="success = false"
                  class="btn btn-clear float-right"
                ></button>
                A zona foi alterado com <strong>sucesso</strong>!
              </div>
            </portal>

            <portal to="toasts">
              <div v-if="error" class="toast toast-error mb-2">
                <button
                  type="button"
                  @click="error = false"
                  class="btn btn-clear float-right"
                ></button>
                Ocorreu um <strong>erro</strong> a tentar guardar a zona.
              </div>
            </portal>
          </form>
        </div>
      </div>
    </template>

    <Modal
      :is-open="isDeleteModalOpen"
      @close="isDeleteModalOpen = false"
      @confirm="deleteZone"
    >
      <template slot="title"
        >Atenção!</template
      >
      <template>
        <div class="flex-centered mb-2 pb-2 text-danger text-error">
          <i class="icon icon-4x icon-delete"></i>
        </div>
        <p class="mb-1 text-gray-dark">Esta acção não é reversível.</p>
        <p class="mb-2 text-gray">
          Todos os locais que pertencem a esta zona vão ficar sem zona.
        </p>
        <p class="mb-0 text-gray-darker">
          Está prestes a <strong>eliminar uma zona</strong>, tem a certeza?
        </p>
      </template>
      <template slot="actions" slot-scope="{ close, confirm }">
        <button type="button" class="btn mr-2" @click="close">Cancelar</button>
        <button type="button" class="btn btn-error" @click="confirm">
          Confirmar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getZone, updateZone, deleteZone } from "../../api/";
import Modal from "../../components/Modal";
import SettingsNav from "../../components/SettingsNav";

export default {
  components: {
    SettingsNav,
    Modal,
  },

  data() {
    return {
      zone: null,
      editZone: null,
      isDeleteModalOpen: false,
      success: false,
      error: false,
      errors: {},
    };
  },

  computed: {
    zoneId() {
      return this.$route.params.zone;
    },
  },

  created() {
    this.fetchZone();
  },

  methods: {
    fetchZone() {
      getZone(this.zoneId)
        .then(({ data }) => {
          this.zone = data.data;
          this.editZone = Object.assign({}, data.data);
        })
        .catch(() => {
          this.$router.push("/404");
        });
    },

    saveZone() {
      this.error = false;
      this.errors = {};

      updateZone(this.zoneId, this.editZone)
        .then(({ data }) => {
          this.zone = data.data;
          this.editZone = Object.assign({}, data.data);
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
        })
        .catch(({ response }) => {
          this.error = true;
          this.errors = response.data.errors;
        });
    },

    deleteZone() {
      deleteZone(this.zoneId).then(() => {
        this.isDeleteModalOpen = false;
        this.$router.push("/settings/zones?deleted=true");
      });
    },
  },
};
</script>
