<template>
  <div
    class="container"
    style="height: calc(100vh - 200px);max-height: calc(100vh - 200px);display: flex;flex-direction: column;justify-content: center;"
  >
    <div class="columns flex-centered">
      <div class="column col-5 col-sm-12">
        <div class="card border-t-primary p-2 text-center">
          <div class="card-body p-2">
            <img
              src="../assets/logo.png"
              alt="Softextint"
              style="height: 100px;margin-bottom: 10px"
            />
            <p class="mb-1">
              Por favor insira as suas credenciais.
            </p>

            <div v-if="error" class="toast toast-error">
              <button
                @click="error = false"
                class="btn btn-clear float-right"
              ></button>
              As credenciais não são válidas!
            </div>

            <form @submit.prevent="login" class="py-2">
              <div class="form-group">
                <input
                  class="form-input"
                  type="email"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,14}$"
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-input"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <div class="form-group">
                <button type="submit" class="w-100 btn btn-primary">
                  Entrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      email: "",
      password: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    login() {
      this.error = false;

      this.$store
        .dispatch("login", { email: this.email, password: this.password })
        .then(() => {
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>
