<template>
  <div>
    <GmapMap ref="map" :center="center" :zoom="zoom" style="width: 100%; height: 100%;">
      <GmapMarker v-if="userLocation" :icon="userLocationIcon" :position="userLocation" />
      <GmapMarker v-if="coordinates" @click="openDirections" :position="coordinates" />
    </GmapMap>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {

  props: {

    place: {
      type: Object,
      required: true,
    },
      
  },

  data() {
    return {
      zoom: 12,
      center: this.place.coordinates ? { lat: parseFloat(this.place.coordinates.lat), lng: parseFloat(parseFloat(this.place.coordinates.lng)) } : {
        lat: 39.433778,
        lng: -8.207259,
      },
      userLocation: null,
      gmapsLoaded: false,
    }
  },

  computed: {
    google: gmapApi,
    
    coordinates() {
      if (! this.place.coordinates || (this.place.coordinates && (! this.place.coordinates.lat || ! this.place.coordinates.lng))) return null
      return { lat: parseFloat(this.place.coordinates.lat), lng: parseFloat(parseFloat(this.place.coordinates.lng)) }
    },
    
    userLocationIcon() {
      if (! this.gmapsLoaded) return null
      return { 
        url: "/assets/user-location.svg",
        size: new this.google.maps.Size(150, 150),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(25, 25),
        scaledSize: new this.google.maps.Size(50, 50),
      }
    },
  },

  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    }
  },

  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      this.gmapsLoaded = true
    })
  },

  methods: {
    openDirections() {
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.place.coordinates.lat},${this.place.coordinates.lng}`)
    },
  },
}
</script>