import { api } from './index.js'

export const getPlaces = (params) => {
  return api('get', '/api/places', null, params);
}

export const createPlace = (clientId, place) => {
  return api('post', `/api/clients/${clientId}/places`, place);
}

export const getPlace = (id) => {
  return api('get', `/api/places/${id}`);
}

export const updatePlace = (id, place) => {
  return api('put', `/api/places/${id}`, place);
}

export const deletePlace = (id) => {
  return api('delete', `/api/places/${id}`);
}

export const getUrgentPlaces = (params) => {
  return api('get', '/api/urgent-places', null, params);
}

export const createUrgentPlace = (place) => {
  return api('post', '/api/urgent-places/', place);
}

export const updateUrgentPlace = (id, notes) => {
  return api('put', `/api/urgent-places/${id}`, { notes });
}

export const deleteUrgentPlace = (id) => {
  return api('delete', `/api/urgent-places/${id}`);
}

export const getNearbyUrgentPlaces = (params) => {
  return api('get', '/api/nearby-urgent-places', null, params);
}