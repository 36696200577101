<template>
<div>
  <GmapMap ref="map" :center="center" :zoom="zoom" style="width: 100%; height: 100%;" @click="place = null">
    <GmapMarker v-if="userLocation" :icon="userLocationIcon" :position="userLocation" />

    <GmapMarker v-for="place in places" :key="place.id" :icon="icon(place)" :position="place.coordinates" :clickable="true" @click="clickPlace(place)" />

    <GmapInfoWindow v-if="place" :options="options" :position="place.coordinates" :opened="place != null" @closeclick="place = null">
      <slot name="infoWindow" :place="place"></slot>
    </GmapInfoWindow>
  </GmapMap>
</div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {

  props: {
    icon: {
      type: Function,
      default: () => {},
    },
    places: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      zoom: 5,
      center: {
        lat: 39.433778,
        lng: -8.207259,
      },
      place: null,
      options: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      userLocation: null,
      gmapsLoaded: false,
    };
  },

  computed: {
    google: gmapApi,

    userLocationIcon() {
      if (! this.gmapsLoaded) return null
      return {
        url: "/assets/user-location.svg",
        size: new this.google.maps.Size(150, 150),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(25, 25),
        scaledSize: new this.google.maps.Size(50, 50),
      }
    },
  },

  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    }
  },

  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      this.gmapsLoaded = true
      this.fitToMarkers()
    })
  },

  methods: {

      fitToMarkers() {
        if (this.places.length > 1) {
          var bounds = new this.google.maps.LatLngBounds()
          this.places.forEach(function(place) {
            if (place.coordinates) {
              bounds.extend({ lat: parseFloat(place.coordinates.lat), lng: parseFloat(place.coordinates.lng) })
            }
          });
          if (this.userLocation) {
            bounds.extend(this.userLocation)
          }
          this.$refs.map.$mapObject.fitBounds(bounds)
        } else {
          if (this.places.length > 0 && this.places[0].coordinates) {
            this.center = { lat: parseFloat(this.places[0].coordinates.lat), lng: parseFloat(this.places[0].coordinates.lng) }
            this.zoom = 12
          }
        }            
      },

    clickPlace(place) {
      if (this.$scopedSlots.infoWindow) {
        this.place = place
      } else {
        this.$router.push(`/clients/${place.client_id}/places/${place.id}`)
      }
    },

  },

}
</script>