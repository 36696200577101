import { api } from './index.js'

export const getEquipmentTypes = () => {
    return api('get', '/api/equipment-types');
}

export const createEquipmentType = (equipmentType) => {
    return api('post', '/api/equipment-types', equipmentType);
}

export const getEquipmentType = (id) => {
    return api('get', `/api/equipment-types/${id}`);
}

export const updateEquipmentType = (id, equipmentType) => {
    return api('put', `/api/equipment-types/${id}`, equipmentType);
}

export const createEquipment = (placeId, equipment) => {
    return api('post', `/api/places/${placeId}/equipments`, equipment);
}

export const updateEquipment = (id, equipment) => {
    return api('put', `/api/equipments/${id}`, equipment);
}

export const updateLastMaintenanceAt = (ids, lastMaintenanceAt) => {
    return api('post', `/api/equipment-maintenances/`, { equipments: ids, last_maintenance_at: lastMaintenanceAt});
}

export const disableEquipments = (ids) => {
    return api('post', `/api/disabled-equipments/`, { equipments: ids });
}

export const deleteEquipment = (id) => {
    return api('delete', `/api/equipments/${id}`);
}