<template>
<ul class="pagination flex-centered">
  <li class="page-item" :class="{ 'disabled': page == 1 }"><a @click.prevent="setPage(page - 1)" href="javascript:" tabindex="-1"><i class="icon icon-arrow-left"></i></a></li>
  
  <li class="page-item" v-for="(p, index) in pages" :key="index" :class="{ 'disabled': p.disabled, 'active': p.current }"><a @click="setPage(p.page)" href="javascript:">{{ p.page }}</a></li>

  <li class="page-item" :class="{ 'disabled': page == lastPage }"><a @click.prevent="setPage(page + 1)" href="javascript:"><i class="icon icon-arrow-right"></i></a></li>
</ul>
</template>

<script>
export default {
	
	props: {

		page: {
			type: Number,
			default: 1,
		},

		lastPage: {
			type: Number,
			required: true,
		},

	},

	computed: {

		pages() {
			const prevPage = this.page - 1
			const nextPage = this.page + 1
			const pages = []
			if (this.page > 1) pages.push({ page: 1, disabled: false, current: false })
			if (prevPage > 2) pages.push({ page: '...', disabled: true, current: false })
			if (prevPage > 1) pages.push({ page: prevPage, disabled: false, current: false })
			pages.push({ page: this.page, disabled: false, current: true })
			if (nextPage < this.lastPage) pages.push({ page: nextPage, disabled: false, current: false })
			if (nextPage < (this.lastPage - 1)) pages.push({ page: '...', disabled: true, current: false })
			if (this.page < this.lastPage) pages.push({ page: this.lastPage, disabled: false, current: false })
			return pages
		},

	},

	watch: {

		lastPage(lastPage) {
			if (this.page > lastPage) {
				this.setPage(lastPage)
			}
		},

	},

	methods: {

		setPage(page) {
			if (page <= 0 || page > this.lastPage || page == '...') {
				return false
			}
			this.$emit('change', page)
		},

	},

}
</script>