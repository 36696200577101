<template>
  <div id="app">
    <header v-if="currentUser" class="navbar">
      <section class="navbar-section">
        <div class="navbar-brand mr-2">
          <img src="./assets/logo.png" alt="Softextint" />

          <button
            @click.prevent="isMobileMenuHidden = !isMobileMenuHidden"
            class="btn btn-link hide-desktop"
          >
            <i class="icon icon-menu"></i>
          </button>
        </div>

        <div class="navbar-menu hide-mobile">
          <router-link class="btn btn-link" to="/lists"
            ><i class="icon icon-bookmark"></i> Listagem</router-link
          >
          <router-link class="btn btn-link" to="/maintenances"
            ><i class="icon icon-link"></i> Oficina</router-link
          >
          <router-link class="btn btn-link" to="/clients"
            ><i class="icon icon-people"></i> Clientes</router-link
          >
          <router-link class="btn btn-link" to="/settings"
            ><i class="icon icon-apps"></i> Definições</router-link
          >
        </div>

        <div
          class="dropdown mt-2"
          :class="{ 'hide-mobile': isMobileMenuHidden }"
        >
          <a href="javascript:;" class="btn btn-link dropdown-toggle">
            <figure class="avatar avatar-sm mr-1">
              <img src="./assets/avatar.png" />
            </figure>
            {{ currentUser.name }}
            <i class="icon icon-caret"></i>
          </a>
          <ul class="menu">
            <li class="menu-item">
              <router-link to="/my-profile"
                ><i class="icon icon-emoji mr-2"></i> Perfil</router-link
              >
            </li>
            <li class="menu-item">
              <a @click.prevent="logout"
                ><i class="icon icon-shutdown mr-2"></i> Sair</a
              >
            </li>
          </ul>
        </div>
      </section>
    </header>

    <router-view />

    <portal-target class="toasts" name="toasts" multiple />

    <p id="footer" class="text-center mt-2 pt-2 text-small">
      {{ new Date().getFullYear() }} © <strong>YellowCAP Ltd.</strong>, Todos os
      direitos reservados
    </p>

    <nav v-if="currentUser" class="hide-desktop mobile-menu-container">
      <div class="mobile-menu">
        <router-link class="mobile-menu-item" to="/lists"
          ><i class="icon icon-bookmark"></i> <span>Listagem</span></router-link
        >
        <router-link class="mobile-menu-item" to="/maintenances"
          ><i class="icon icon-link"></i> <span>Oficina</span></router-link
        >
        <router-link class="mobile-menu-item" to="/clients"
          ><i class="icon icon-people"></i> <span>Clientes</span></router-link
        >
        <router-link class="mobile-menu-item" to="/settings"
          ><i class="icon icon-apps"></i> <span>Definições</span></router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guestRoutes: ["home", "login"],
      isMobileMenuHidden: true,
      isNativeApp: false,
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  watch: {
    $route() {
      this.isMobileMenuHidden = true;
    },
  },

  created() {
    this.login();
  },

  methods: {
    login() {
      const token = JSON.parse(localStorage.getItem("se-token"));
      this.$store
        .dispatch("loginWithToken", token)
        .then(() => {
          if (this.guestRoutes.includes(this.$route.name)) {
            this.$router.push("/dashboard");
          }
        })
        .catch(() => {
          if (!this.guestRoutes.includes(this.$route.name)) {
            this.$router.push("/login");
          }
        });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html, body, #app {
	min-height: 100vh;
	background-color: #eee;
}

body {
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left) !important;
}

.navbar {
	padding-top: calc(env(safe-area-inset-top) + 10px) !important;
}

.mobile-menu-container {
	padding-bottom: env(safe-area-inset-bottom) !important;
}

body,
#app {
  background-color: #eee;
  width: 100vw;
  max-width: 100vw;
}
#app > .container {
  max-width: 1024px;
  padding: 20px 15px;
}
a,
.btn.btn-link,
a:visited {
  cursor: pointer;
  color: #ff742e;
}
.btn {
  border: 0.05rem solid #ff742e;
  color: #ff742e;
}
.btn:focus,
.btn:hover {
  background: #fff0e9;
  border-color: #ff742e;
}
.btn:active {
  background: #ff742e;
  border-color: #fff;
}

.btn.btn-primary {
  background: #ff742e;
  border-color: #ff742e;
  color: #fff;
}

.tab .tab-item a.active,
.tab .tab-item.active a {
  border-bottom-color: #ff742e;
  color: #ff742e;
}

.btn.btn-primary:focus,
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background: #ff742e;
  border-color: #ff742e;
}

.form-input:focus {
  border-color: #ff742e;
  box-shadow: 0 0 0 0.1rem rgba(225, 75, 0, 0.2);
}

.pagination .page-item.active a {
  background: #ff742e;
  color: #fff;
}
.pagination .page-item a:hover {
  color: #e14b00;
}
a.active,
a:active,
a:focus,
a:hover,
.btn.btn-link.active,
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover,
.router-link-exact-active,
.router-link-active,
.tab .tab-item a:focus,
.tab .tab-item a:hover {
  color: #e14b00;
  background: transparent;
  border-color: transparent;
}
a:focus,
.btn.btn-link:focus,
.btn:focus {
  box-shadow: 0 0 0 0.1rem rgba(225, 75, 0, 0.2);
}

.menu .menu-item > a:focus,
.menu .menu-item > a:hover,
.menu .menu-item > a.active,
.menu .menu-item > a:active {
  color: #ff742e;
  background: #fff0e9;
}

.form-checkbox input:checked + .form-icon,
.form-radio input:checked + .form-icon,
.form-switch input:checked + .form-icon,
.form-checkbox input:indeterminate + .form-icon {
  background: #ff742e;
  border-color: #ff742e;
}

.form-checkbox input:focus + .form-icon,
.form-radio input:focus + .form-icon,
.form-switch input:focus + .form-icon {
  border-color: #ff742e;
  box-shadow: 0 0 0 0.1rem rgba(225, 75, 0, 0.2);
}

.loading::after {
  border: 0.1rem solid #ff742e;
  border-right-color: transparent;
  border-top-color: transparent;
}

.navbar {
  background-color: #fff;
  border: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 15px;
}
.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-brand img {
  height: 40px;
}
.navbar-section .menu {
  right: 0;
  left: auto;
}
.navbar-menu .btn.btn-link {
  color: inherit;
}
.navbar-menu .btn.btn-link.active,
.navbar-menu .btn.btn-link:active,
.navbar-menu .btn.btn-link:focus,
.navbar-menu .btn.btn-link:hover,
.navbar-menu .btn.btn-link.router-link-exact-active,
.navbar-menu .btn.btn-link.router-link-active {
  color: #e14b00;
  background: transparent;
  border-color: transparent;
}
.navbar-menu .btn.btn-link:focus {
  box-shadow: 0 0 0 0.1rem rgba(225, 75, 0, 0.2);
}

.card.card-condensed .card-body,
.card.card-condensed .card-footer {
  padding: 0;
}

.table td,
.table th {
  padding: 0.6rem 0.8rem;
}

.avatar {
  background: #ff742e;
}

.border-t-primary {
  border-top: 2px solid #ff742e;
}

.w-100 {
  width: 100%;
}

.bg-t-warning {
  background: rgba(255, 183, 0, 0.2) !important;
}

.bg-t-error {
  background: rgba(232, 86, 0, 0.2) !important;
}

.text-small {
  font-size: 0.8em;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1 1 0%;
}

.opacity-25 {
  opacity: 0.25;
}

.items-center {
  align-items: center;
}

.vw-100 {
  width: 100vw;
}

.text-gray-dark {
  color: #838890 !important;
}

.text-gray-darker {
  color: #55595f !important;
}

.toasts {
  position: fixed;
  right: 30px;
  bottom: calc(env(safe-area-inset-bottom) + 30px);
  width: 350px;
  z-index: 9999;
}

hr {
  border: 0;
  border-top: 1px solid #dadee4;
}

.settings-nav,
.lists-nav {
  margin-bottom: 40px;
}

.lists-nav li a {
  text-transform: capitalize;
}

.navbar-menu {
  flex: 1;
}

.gmap-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 25px;
  left: 0;
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  outline: none;
}

.gmap-btn:hover {
  background: none rgb(235, 235, 235);
}

.gmap-btn img {
  height: 22px;
}

@media only screen and (min-width: 1023px) {
  .hide-desktop {
    display: none !important;
  }

  #nearbymap {
    height: calc(100vh - 224px);
  }
}

@media only screen and (max-width: 1023px) {
  .hide-mobile {
    display: none !important;
  }

  #app {
    position: relative;
    max-height: 100vh;
    overflow: hidden;
  }

  #app > .container {
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding-top: calc(61px + 20px + env(safe-area-inset-top));
    padding-bottom: calc(75px + 20px + env(safe-area-inset-bottom));
  }

  #footer {
    display: none;
  }

  .toasts {
    bottom: calc(75px + 20px + env(safe-area-inset-bottom));
  }

  #nearbymap {
    height: calc(100vh - 218px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 100vw;
    margin: 0 -15px -20px;
  }

  .mobile-menu-container {
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    padding: 0.25rem;
    border-top: 1px solid #e6e6e6;
  }

  .mobile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-menu-item {
    color: #3b4351 !important;
    text-decoration: none !important;
    flex: 1;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid transparent;
  }

  .mobile-menu-item.router-link-active {
    color: #ff742e !important;
    border-bottom: 2px solid #ff742e;
  }

  .mobile-menu-item i {
    margin-bottom: 0.25rem;
  }

  .mobile-menu-item span {
    font-size: 14px;
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    max-width: 100vw;
  }

  .navbar .navbar-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navbar-brand {
    width: 100%;
    justify-content: space-between;
  }

  .navbar-menu {
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }

  .navbar-menu a {
    text-align: left;
    margin: 0.2rem 0;
    padding-left: 0;
  }

  .navbar-menu .btn.btn-link.active,
  .navbar-menu .btn.btn-link:active,
  .navbar-menu .btn.btn-link:focus,
  .navbar-menu .btn.btn-link:hover,
  .navbar-menu .btn.btn-link.router-link-exact-active,
  .navbar-menu .btn.btn-link.router-link-active {
    color: #ff742e;
    background: #fff0e9;
  }

  .navbar .dropdown {
    width: 100%;
  }

  .navbar .dropdown .dropdown-toggle {
    display: none;
  }

  .navbar .dropdown .menu {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    position: relative;
    padding: 0;
    top: auto;
    animation: none;
  }

  .navbar .dropdown .menu .menu-item {
    padding-left: 0;
  }

  .navbar .dropdown .menu .menu-item a.router-link-exact-active,
  .navbar .dropdown .menu .menu-item a.router-link-active {
    color: #ff742e;
    background: #fff0e9;
  }

  .card-table {
    margin: 0 -15px;
    overflow: hidden;
  }

  .lists-nav {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 0.5rem;
  }
}
</style>
